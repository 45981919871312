@font-face {
    font-family: 'icomoon';
    src:  url('../font/icomoon/icomoon.eot?loatqp');
    src:  url('../font/icomoon/icomoon.eot?loatqp#iefix') format('embedded-opentype'),
    url('../font/icomoon/icomoon.ttf?loatqp') format('truetype'),
    url('../font/icomoon/icomoon.woff?loatqp') format('woff'),
    url('../font/icomoon/icomoon.svg?loatqp#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
    content: "\e900";
}
.icon-home2:before {
    content: "\e901";
}
.icon-home3:before {
    content: "\e902";
}
.icon-home4:before {
    content: "\e903";
}
.icon-home5:before {
    content: "\e904";
}
.icon-home6:before {
    content: "\e905";
}
.icon-home7:before {
    content: "\e906";
}
.icon-home8:before {
    content: "\e907";
}
.icon-home9:before {
    content: "\e908";
}
.icon-office:before {
    content: "\e909";
}
.icon-city:before {
    content: "\e90a";
}
.icon-newspaper:before {
    content: "\e90b";
}
.icon-magazine:before {
    content: "\e90c";
}
.icon-design:before {
    content: "\e90d";
}
.icon-pencil:before {
    content: "\e90e";
}
.icon-pencil2:before {
    content: "\e90f";
}
.icon-pencil3:before {
    content: "\e910";
}
.icon-pencil4:before {
    content: "\e911";
}
.icon-pencil5:before {
    content: "\e912";
}
.icon-pencil6:before {
    content: "\e913";
}
.icon-pencil7:before {
    content: "\e914";
}
.icon-eraser:before {
    content: "\e915";
}
.icon-eraser2:before {
    content: "\e916";
}
.icon-eraser3:before {
    content: "\e917";
}
.icon-quill:before {
    content: "\e918";
}
.icon-quill2:before {
    content: "\e919";
}
.icon-quill3:before {
    content: "\e91a";
}
.icon-quill4:before {
    content: "\e91b";
}
.icon-pen:before {
    content: "\e91c";
}
.icon-pen-plus:before {
    content: "\e91d";
}
.icon-pen-minus:before {
    content: "\e91e";
}
.icon-pen2:before {
    content: "\e91f";
}
.icon-pen3:before {
    content: "\e920";
}
.icon-pen4:before {
    content: "\e921";
}
.icon-pen5:before {
    content: "\e922";
}
.icon-marker:before {
    content: "\e923";
}
.icon-marker2:before {
    content: "\e924";
}
.icon-blog:before {
    content: "\e925";
}
.icon-blog2:before {
    content: "\e926";
}
.icon-pen6:before {
    content: "\e927";
}
.icon-brush:before {
    content: "\e928";
}
.icon-spray:before {
    content: "\e929";
}
.icon-palette:before {
    content: "\e92a";
}
.icon-palette2:before {
    content: "\e92b";
}
.icon-color-sampler:before {
    content: "\e92c";
}
.icon-toggle:before {
    content: "\e92d";
}
.icon-bucket:before {
    content: "\e92e";
}
.icon-bucket2:before {
    content: "\e92f";
}
.icon-gradient:before {
    content: "\e930";
}
.icon-eyedropper:before {
    content: "\e931";
}
.icon-eyedropper2:before {
    content: "\e932";
}
.icon-eyedropper3:before {
    content: "\e933";
}
.icon-droplet:before {
    content: "\e934";
}
.icon-droplet2:before {
    content: "\e935";
}
.icon-droplet-contrast:before {
    content: "\e936";
}
.icon-color-clear:before {
    content: "\e937";
}
.icon-paint-format:before {
    content: "\e938";
}
.icon-stamp:before {
    content: "\e939";
}
.icon-images:before {
    content: "\e93a";
}
.icon-image:before {
    content: "\e93b";
}
.icon-image2:before {
    content: "\e93c";
}
.icon-image-compare:before {
    content: "\e93d";
}
.icon-images2:before {
    content: "\e93e";
}
.icon-image3:before {
    content: "\e93f";
}
.icon-images3:before {
    content: "\e940";
}
.icon-image4:before {
    content: "\e941";
}
.icon-image5:before {
    content: "\e942";
}
.icon-image6:before {
    content: "\e943";
}
.icon-camera:before {
    content: "\e944";
}
.icon-camera2:before {
    content: "\e945";
}
.icon-camera3:before {
    content: "\e946";
}
.icon-shutter:before {
    content: "\e947";
}
.icon-headphones:before {
    content: "\e948";
}
.icon-headset:before {
    content: "\e949";
}
.icon-music:before {
    content: "\e94a";
}
.icon-music2:before {
    content: "\e94b";
}
.icon-music3:before {
    content: "\e94c";
}
.icon-music4:before {
    content: "\e94d";
}
.icon-music5:before {
    content: "\e94e";
}
.icon-music6:before {
    content: "\e94f";
}
.icon-album:before {
    content: "\e950";
}
.icon-cassette:before {
    content: "\e951";
}
.icon-tape:before {
    content: "\e952";
}
.icon-piano:before {
    content: "\e953";
}
.icon-guitar:before {
    content: "\e954";
}
.icon-trumpet:before {
    content: "\e955";
}
.icon-speakers:before {
    content: "\e956";
}
.icon-play:before {
    content: "\e957";
}
.icon-play2:before {
    content: "\e958";
}
.icon-clapboard-play:before {
    content: "\e959";
}
.icon-clapboard:before {
    content: "\e95a";
}
.icon-media:before {
    content: "\e95b";
}
.icon-presentation:before {
    content: "\e95c";
}
.icon-movie:before {
    content: "\e95d";
}
.icon-film:before {
    content: "\e95e";
}
.icon-film2:before {
    content: "\e95f";
}
.icon-film3:before {
    content: "\e960";
}
.icon-film4:before {
    content: "\e961";
}
.icon-video-camera:before {
    content: "\e962";
}
.icon-video-camera2:before {
    content: "\e963";
}
.icon-video-camera-slash:before {
    content: "\e964";
}
.icon-video-camera3:before {
    content: "\e965";
}
.icon-video-camera4:before {
    content: "\e966";
}
.icon-video-camera-slash2:before {
    content: "\e967";
}
.icon-video-camera5:before {
    content: "\e968";
}
.icon-recording:before {
    content: "\e969";
}
.icon-dice:before {
    content: "\e96a";
}
.icon-gamepad:before {
    content: "\e96b";
}
.icon-gamepad2:before {
    content: "\e96c";
}
.icon-gamepad3:before {
    content: "\e96d";
}
.icon-pacman:before {
    content: "\e96e";
}
.icon-spades:before {
    content: "\e96f";
}
.icon-clubs:before {
    content: "\e970";
}
.icon-diamonds:before {
    content: "\e971";
}
.icon-chess-king:before {
    content: "\e972";
}
.icon-chess-queen:before {
    content: "\e973";
}
.icon-chess-rook:before {
    content: "\e974";
}
.icon-chess-bishop:before {
    content: "\e975";
}
.icon-chess-knight:before {
    content: "\e976";
}
.icon-chess-pawn:before {
    content: "\e977";
}
.icon-chess:before {
    content: "\e978";
}
.icon-bullhorn:before {
    content: "\e979";
}
.icon-megaphone:before {
    content: "\e97a";
}
.icon-new:before {
    content: "\e97b";
}
.icon-connection:before {
    content: "\e97c";
}
.icon-connection2:before {
    content: "\e97d";
}
.icon-connection3:before {
    content: "\e97e";
}
.icon-connection4:before {
    content: "\e97f";
}
.icon-podcast:before {
    content: "\e980";
}
.icon-station:before {
    content: "\e981";
}
.icon-station2:before {
    content: "\e982";
}
.icon-antenna:before {
    content: "\e983";
}
.icon-satellite-dish:before {
    content: "\e984";
}
.icon-satellite-dish2:before {
    content: "\e985";
}
.icon-feed:before {
    content: "\e986";
}
.icon-podcast2:before {
    content: "\e987";
}
.icon-antenna2:before {
    content: "\e988";
}
.icon-mic:before {
    content: "\e989";
}
.icon-mic-off:before {
    content: "\e98a";
}
.icon-mic2:before {
    content: "\e98b";
}
.icon-mic-off2:before {
    content: "\e98c";
}
.icon-mic3:before {
    content: "\e98d";
}
.icon-mic4:before {
    content: "\e98e";
}
.icon-mic5:before {
    content: "\e98f";
}
.icon-book:before {
    content: "\e990";
}
.icon-book2:before {
    content: "\e991";
}
.icon-book-play:before {
    content: "\e992";
}
.icon-book3:before {
    content: "\e993";
}
.icon-bookmark:before {
    content: "\e994";
}
.icon-books:before {
    content: "\e995";
}
.icon-archive:before {
    content: "\e996";
}
.icon-reading:before {
    content: "\e997";
}
.icon-library:before {
    content: "\e998";
}
.icon-library2:before {
    content: "\e999";
}
.icon-graduation:before {
    content: "\e99a";
}
.icon-file-text:before {
    content: "\e99b";
}
.icon-profile:before {
    content: "\e99c";
}
.icon-file-empty:before {
    content: "\e99d";
}
.icon-file-empty2:before {
    content: "\e99e";
}
.icon-files-empty:before {
    content: "\e99f";
}
.icon-files-empty2:before {
    content: "\e9a0";
}
.icon-file-plus:before {
    content: "\e9a1";
}
.icon-file-plus2:before {
    content: "\e9a2";
}
.icon-file-minus:before {
    content: "\e9a3";
}
.icon-file-minus2:before {
    content: "\e9a4";
}
.icon-file-download:before {
    content: "\e9a5";
}
.icon-file-download2:before {
    content: "\e9a6";
}
.icon-file-upload:before {
    content: "\e9a7";
}
.icon-file-upload2:before {
    content: "\e9a8";
}
.icon-file-check:before {
    content: "\e9a9";
}
.icon-file-check2:before {
    content: "\e9aa";
}
.icon-file-eye:before {
    content: "\e9ab";
}
.icon-file-eye2:before {
    content: "\e9ac";
}
.icon-file-text2:before {
    content: "\e9ad";
}
.icon-file-text3:before {
    content: "\e9ae";
}
.icon-file-picture:before {
    content: "\e9af";
}
.icon-file-picture2:before {
    content: "\e9b0";
}
.icon-file-music:before {
    content: "\e9b1";
}
.icon-file-music2:before {
    content: "\e9b2";
}
.icon-file-play:before {
    content: "\e9b3";
}
.icon-file-play2:before {
    content: "\e9b4";
}
.icon-file-video:before {
    content: "\e9b5";
}
.icon-file-video2:before {
    content: "\e9b6";
}
.icon-copy:before {
    content: "\e9b7";
}
.icon-copy2:before {
    content: "\e9b8";
}
.icon-file-zip:before {
    content: "\e9b9";
}
.icon-file-zip2:before {
    content: "\e9ba";
}
.icon-file-xml:before {
    content: "\e9bb";
}
.icon-file-xml2:before {
    content: "\e9bc";
}
.icon-file-css:before {
    content: "\e9bd";
}
.icon-file-css2:before {
    content: "\e9be";
}
.icon-file-presentation:before {
    content: "\e9bf";
}
.icon-file-presentation2:before {
    content: "\e9c0";
}
.icon-file-stats:before {
    content: "\e9c1";
}
.icon-file-stats2:before {
    content: "\e9c2";
}
.icon-file-locked:before {
    content: "\e9c3";
}
.icon-file-locked2:before {
    content: "\e9c4";
}
.icon-file-spreadsheet:before {
    content: "\e9c5";
}
.icon-file-spreadsheet2:before {
    content: "\e9c6";
}
.icon-copy3:before {
    content: "\e9c7";
}
.icon-copy4:before {
    content: "\e9c8";
}
.icon-paste:before {
    content: "\e9c9";
}
.icon-paste2:before {
    content: "\e9ca";
}
.icon-paste3:before {
    content: "\e9cb";
}
.icon-paste4:before {
    content: "\e9cc";
}
.icon-stack:before {
    content: "\e9cd";
}
.icon-stack2:before {
    content: "\e9ce";
}
.icon-stack3:before {
    content: "\e9cf";
}
.icon-folder:before {
    content: "\e9d0";
}
.icon-folder-search:before {
    content: "\e9d1";
}
.icon-folder-download:before {
    content: "\e9d2";
}
.icon-folder-upload:before {
    content: "\e9d3";
}
.icon-folder-plus:before {
    content: "\e9d4";
}
.icon-folder-plus2:before {
    content: "\e9d5";
}
.icon-folder-minus:before {
    content: "\e9d6";
}
.icon-folder-minus2:before {
    content: "\e9d7";
}
.icon-folder-check:before {
    content: "\e9d8";
}
.icon-folder-heart:before {
    content: "\e9d9";
}
.icon-folder-remove:before {
    content: "\e9da";
}
.icon-folder2:before {
    content: "\e9db";
}
.icon-folder-open:before {
    content: "\e9dc";
}
.icon-folder3:before {
    content: "\e9dd";
}
.icon-folder4:before {
    content: "\e9de";
}
.icon-folder-plus3:before {
    content: "\e9df";
}
.icon-folder-minus3:before {
    content: "\e9e0";
}
.icon-folder-plus4:before {
    content: "\e9e1";
}
.icon-folder-minus4:before {
    content: "\e9e2";
}
.icon-folder-download2:before {
    content: "\e9e3";
}
.icon-folder-upload2:before {
    content: "\e9e4";
}
.icon-folder-download3:before {
    content: "\e9e5";
}
.icon-folder-upload3:before {
    content: "\e9e6";
}
.icon-folder5:before {
    content: "\e9e7";
}
.icon-folder-open2:before {
    content: "\e9e8";
}
.icon-folder6:before {
    content: "\e9e9";
}
.icon-folder-open3:before {
    content: "\e9ea";
}
.icon-certificate:before {
    content: "\e9eb";
}
.icon-cc:before {
    content: "\e9ec";
}
.icon-price-tag:before {
    content: "\e9ed";
}
.icon-price-tag2:before {
    content: "\e9ee";
}
.icon-price-tags:before {
    content: "\e9ef";
}
.icon-price-tag3:before {
    content: "\e9f0";
}
.icon-price-tags2:before {
    content: "\e9f1";
}
.icon-price-tag4:before {
    content: "\e9f2";
}
.icon-price-tags3:before {
    content: "\e9f3";
}
.icon-price-tag5:before {
    content: "\e9f4";
}
.icon-price-tags4:before {
    content: "\e9f5";
}
.icon-barcode:before {
    content: "\e9f6";
}
.icon-barcode2:before {
    content: "\e9f7";
}
.icon-qrcode:before {
    content: "\e9f8";
}
.icon-ticket:before {
    content: "\e9f9";
}
.icon-ticket2:before {
    content: "\e9fa";
}
.icon-theater:before {
    content: "\e9fb";
}
.icon-store:before {
    content: "\e9fc";
}
.icon-store2:before {
    content: "\e9fd";
}
.icon-cart:before {
    content: "\e9fe";
}
.icon-cart2:before {
    content: "\e9ff";
}
.icon-cart3:before {
    content: "\ea00";
}
.icon-cart4:before {
    content: "\ea01";
}
.icon-cart5:before {
    content: "\ea02";
}
.icon-cart-add:before {
    content: "\ea03";
}
.icon-cart-add2:before {
    content: "\ea04";
}
.icon-cart-remove:before {
    content: "\ea05";
}
.icon-basket:before {
    content: "\ea06";
}
.icon-bag:before {
    content: "\ea07";
}
.icon-bag2:before {
    content: "\ea08";
}
.icon-percent:before {
    content: "\ea09";
}
.icon-coins:before {
    content: "\ea0a";
}
.icon-coin-dollar:before {
    content: "\ea0b";
}
.icon-coin-euro:before {
    content: "\ea0c";
}
.icon-coin-pound:before {
    content: "\ea0d";
}
.icon-coin-yen:before {
    content: "\ea0e";
}
.icon-piggy-bank:before {
    content: "\ea0f";
}
.icon-wallet:before {
    content: "\ea10";
}
.icon-cash:before {
    content: "\ea11";
}
.icon-cash2:before {
    content: "\ea12";
}
.icon-cash3:before {
    content: "\ea13";
}
.icon-cash4:before {
    content: "\ea14";
}
.icon-credit-card:before {
    content: "\ea15";
}
.icon-credit-card2:before {
    content: "\ea16";
}
.icon-calculator:before {
    content: "\ea17";
}
.icon-calculator2:before {
    content: "\ea18";
}
.icon-calculator3:before {
    content: "\ea19";
}
.icon-chip:before {
    content: "\ea1a";
}
.icon-lifebuoy:before {
    content: "\ea1b";
}
.icon-phone:before {
    content: "\ea1c";
}
.icon-phone2:before {
    content: "\ea1d";
}
.icon-phone-slash:before {
    content: "\ea1e";
}
.icon-phone-wave:before {
    content: "\ea1f";
}
.icon-phone-plus:before {
    content: "\ea20";
}
.icon-phone-minus:before {
    content: "\ea21";
}
.icon-phone-plus2:before {
    content: "\ea22";
}
.icon-phone-minus2:before {
    content: "\ea23";
}
.icon-phone-incoming:before {
    content: "\ea24";
}
.icon-phone-outgoing:before {
    content: "\ea25";
}
.icon-phone3:before {
    content: "\ea26";
}
.icon-phone-slash2:before {
    content: "\ea27";
}
.icon-phone-wave2:before {
    content: "\ea28";
}
.icon-phone-hang-up:before {
    content: "\ea29";
}
.icon-phone-hang-up2:before {
    content: "\ea2a";
}
.icon-address-book:before {
    content: "\ea2b";
}
.icon-address-book2:before {
    content: "\ea2c";
}
.icon-address-book3:before {
    content: "\ea2d";
}
.icon-notebook:before {
    content: "\ea2e";
}
.icon-at-sign:before {
    content: "\ea2f";
}
.icon-envelop:before {
    content: "\ea30";
}
.icon-envelop2:before {
    content: "\ea31";
}
.icon-envelop3:before {
    content: "\ea32";
}
.icon-envelop4:before {
    content: "\ea33";
}
.icon-envelop5:before {
    content: "\ea34";
}
.icon-mailbox:before {
    content: "\ea35";
}
.icon-pushpin:before {
    content: "\ea36";
}
.icon-pushpin2:before {
    content: "\ea37";
}
.icon-location:before {
    content: "\ea38";
}
.icon-location2:before {
    content: "\ea39";
}
.icon-location3:before {
    content: "\ea3a";
}
.icon-location4:before {
    content: "\ea3b";
}
.icon-location5:before {
    content: "\ea3c";
}
.icon-location6:before {
    content: "\ea3d";
}
.icon-location7:before {
    content: "\ea3e";
}
.icon-compass:before {
    content: "\ea3f";
}
.icon-compass2:before {
    content: "\ea40";
}
.icon-compass3:before {
    content: "\ea41";
}
.icon-compass4:before {
    content: "\ea42";
}
.icon-compass5:before {
    content: "\ea43";
}
.icon-compass6:before {
    content: "\ea44";
}
.icon-map:before {
    content: "\ea45";
}
.icon-map2:before {
    content: "\ea46";
}
.icon-map3:before {
    content: "\ea47";
}
.icon-map4:before {
    content: "\ea48";
}
.icon-map5:before {
    content: "\ea49";
}
.icon-direction:before {
    content: "\ea4a";
}
.icon-reset:before {
    content: "\ea4b";
}
.icon-history:before {
    content: "\ea4c";
}
.icon-clock:before {
    content: "\ea4d";
}
.icon-clock2:before {
    content: "\ea4e";
}
.icon-clock3:before {
    content: "\ea4f";
}
.icon-clock4:before {
    content: "\ea50";
}
.icon-watch:before {
    content: "\ea51";
}
.icon-watch2:before {
    content: "\ea52";
}
.icon-alarm:before {
    content: "\ea53";
}
.icon-alarm-add:before {
    content: "\ea54";
}
.icon-alarm-check:before {
    content: "\ea55";
}
.icon-alarm-cancel:before {
    content: "\ea56";
}
.icon-bell:before {
    content: "\ea57";
}
.icon-bell2:before {
    content: "\ea58";
}
.icon-bell3:before {
    content: "\ea59";
}
.icon-bell-plus:before {
    content: "\ea5a";
}
.icon-bell-minus:before {
    content: "\ea5b";
}
.icon-bell-check:before {
    content: "\ea5c";
}
.icon-bell-cross:before {
    content: "\ea5d";
}
.icon-stopwatch:before {
    content: "\ea5e";
}
.icon-calendar:before {
    content: "\ea5f";
}
.icon-calendar2:before {
    content: "\ea60";
}
.icon-calendar3:before {
    content: "\ea61";
}
.icon-calendar4:before {
    content: "\ea62";
}
.icon-calendar5:before {
    content: "\ea63";
}
.icon-calendar-empty:before {
    content: "\ea64";
}
.icon-calendar-day:before {
    content: "\ea65";
}
.icon-calendar-week:before {
    content: "\ea66";
}
.icon-printer:before {
    content: "\ea67";
}
.icon-printer2:before {
    content: "\ea68";
}
.icon-printer3:before {
    content: "\ea69";
}
.icon-printer4:before {
    content: "\ea6a";
}
.icon-shredder:before {
    content: "\ea6b";
}
.icon-mouse:before {
    content: "\ea6c";
}
.icon-mouse2:before {
    content: "\ea6d";
}
.icon-mouse-left:before {
    content: "\ea6e";
}
.icon-mouse-right:before {
    content: "\ea6f";
}
.icon-keyboard:before {
    content: "\ea70";
}
.icon-keyboard2:before {
    content: "\ea71";
}
.icon-typewriter:before {
    content: "\ea72";
}
.icon-display:before {
    content: "\ea73";
}
.icon-display2:before {
    content: "\ea74";
}
.icon-display3:before {
    content: "\ea75";
}
.icon-display4:before {
    content: "\ea76";
}
.icon-laptop:before {
    content: "\ea77";
}
.icon-mobile:before {
    content: "\ea78";
}
.icon-mobile2:before {
    content: "\ea79";
}
.icon-tablet:before {
    content: "\ea7a";
}
.icon-mobile3:before {
    content: "\ea7b";
}
.icon-tv:before {
    content: "\ea7c";
}
.icon-radio:before {
    content: "\ea7d";
}
.icon-cabinet:before {
    content: "\ea7e";
}
.icon-drawer:before {
    content: "\ea7f";
}
.icon-drawer2:before {
    content: "\ea80";
}
.icon-drawer-out:before {
    content: "\ea81";
}
.icon-drawer-in:before {
    content: "\ea82";
}
.icon-drawer3:before {
    content: "\ea83";
}
.icon-box:before {
    content: "\ea84";
}
.icon-box-add:before {
    content: "\ea85";
}
.icon-box-remove:before {
    content: "\ea86";
}
.icon-download:before {
    content: "\ea87";
}
.icon-upload:before {
    content: "\ea88";
}
.icon-floppy-disk:before {
    content: "\ea89";
}
.icon-floppy-disks:before {
    content: "\ea8a";
}
.icon-floppy-disks2:before {
    content: "\ea8b";
}
.icon-floppy-disk2:before {
    content: "\ea8c";
}
.icon-floppy-disk3:before {
    content: "\ea8d";
}
.icon-disk:before {
    content: "\ea8e";
}
.icon-disk2:before {
    content: "\ea8f";
}
.icon-usb-stick:before {
    content: "\ea90";
}
.icon-drive:before {
    content: "\ea91";
}
.icon-server:before {
    content: "\ea92";
}
.icon-database:before {
    content: "\ea93";
}
.icon-database2:before {
    content: "\ea94";
}
.icon-database3:before {
    content: "\ea95";
}
.icon-database4:before {
    content: "\ea96";
}
.icon-database-menu:before {
    content: "\ea97";
}
.icon-database-add:before {
    content: "\ea98";
}
.icon-database-remove:before {
    content: "\ea99";
}
.icon-database-insert:before {
    content: "\ea9a";
}
.icon-database-export:before {
    content: "\ea9b";
}
.icon-database-upload:before {
    content: "\ea9c";
}
.icon-database-refresh:before {
    content: "\ea9d";
}
.icon-database-diff:before {
    content: "\ea9e";
}
.icon-database-edit:before {
    content: "\ea9f";
}
.icon-database-edit2:before {
    content: "\eaa0";
}
.icon-database-check:before {
    content: "\eaa1";
}
.icon-database-arrow:before {
    content: "\eaa2";
}
.icon-database-time:before {
    content: "\eaa3";
}
.icon-database-time2:before {
    content: "\eaa4";
}
.icon-undo:before {
    content: "\eaa5";
}
.icon-redo:before {
    content: "\eaa6";
}
.icon-rotate-ccw:before {
    content: "\eaa7";
}
.icon-rotate-cw:before {
    content: "\eaa8";
}
.icon-rotate-ccw2:before {
    content: "\eaa9";
}
.icon-rotate-cw2:before {
    content: "\eaaa";
}
.icon-rotate-ccw3:before {
    content: "\eaab";
}
.icon-rotate-cw3:before {
    content: "\eaac";
}
.icon-flip-vertical:before {
    content: "\eaad";
}
.icon-flip-vertical2:before {
    content: "\eaae";
}
.icon-flip-horizontal:before {
    content: "\eaaf";
}
.icon-flip-horizontal2:before {
    content: "\eab0";
}
.icon-flip-vertical3:before {
    content: "\eab1";
}
.icon-flip-vertical4:before {
    content: "\eab2";
}
.icon-angle:before {
    content: "\eab3";
}
.icon-shear:before {
    content: "\eab4";
}
.icon-unite:before {
    content: "\eab5";
}
.icon-subtract:before {
    content: "\eab6";
}
.icon-interset:before {
    content: "\eab7";
}
.icon-exclude:before {
    content: "\eab8";
}
.icon-align-left:before {
    content: "\eab9";
}
.icon-align-center-horizontal:before {
    content: "\eaba";
}
.icon-align-right:before {
    content: "\eabb";
}
.icon-align-top:before {
    content: "\eabc";
}
.icon-align-center-vertical:before {
    content: "\eabd";
}
.icon-align-bottom:before {
    content: "\eabe";
}
.icon-undo2:before {
    content: "\eabf";
}
.icon-redo2:before {
    content: "\eac0";
}
.icon-forward:before {
    content: "\eac1";
}
.icon-reply:before {
    content: "\eac2";
}
.icon-reply-all:before {
    content: "\eac3";
}
.icon-bubble:before {
    content: "\eac4";
}
.icon-bubbles:before {
    content: "\eac5";
}
.icon-bubbles2:before {
    content: "\eac6";
}
.icon-bubble2:before {
    content: "\eac7";
}
.icon-bubbles3:before {
    content: "\eac8";
}
.icon-bubbles4:before {
    content: "\eac9";
}
.icon-bubble-notification:before {
    content: "\eaca";
}
.icon-bubbles5:before {
    content: "\eacb";
}
.icon-bubbles6:before {
    content: "\eacc";
}
.icon-bubble3:before {
    content: "\eacd";
}
.icon-bubble-dots:before {
    content: "\eace";
}
.icon-bubble-lines:before {
    content: "\eacf";
}
.icon-bubble4:before {
    content: "\ead0";
}
.icon-bubble-dots2:before {
    content: "\ead1";
}
.icon-bubble-lines2:before {
    content: "\ead2";
}
.icon-bubble5:before {
    content: "\ead3";
}
.icon-bubble6:before {
    content: "\ead4";
}
.icon-bubbles7:before {
    content: "\ead5";
}
.icon-bubble7:before {
    content: "\ead6";
}
.icon-bubbles8:before {
    content: "\ead7";
}
.icon-bubble8:before {
    content: "\ead8";
}
.icon-bubble-dots3:before {
    content: "\ead9";
}
.icon-bubble-lines3:before {
    content: "\eada";
}
.icon-bubble9:before {
    content: "\eadb";
}
.icon-bubble-dots4:before {
    content: "\eadc";
}
.icon-bubble-lines4:before {
    content: "\eadd";
}
.icon-bubbles9:before {
    content: "\eade";
}
.icon-bubbles10:before {
    content: "\eadf";
}
.icon-bubble-blocked:before {
    content: "\eae0";
}
.icon-bubble-quote:before {
    content: "\eae1";
}
.icon-bubble-user:before {
    content: "\eae2";
}
.icon-bubble-smiley:before {
    content: "\eae3";
}
.icon-bubble-check:before {
    content: "\eae4";
}
.icon-bubble-video:before {
    content: "\eae5";
}
.icon-bubble-link:before {
    content: "\eae6";
}
.icon-bubble-lock:before {
    content: "\eae7";
}
.icon-bubble-star:before {
    content: "\eae8";
}
.icon-bubble-heart:before {
    content: "\eae9";
}
.icon-bubble-paperclip:before {
    content: "\eaea";
}
.icon-bubble-cancel:before {
    content: "\eaeb";
}
.icon-bubble-plus:before {
    content: "\eaec";
}
.icon-bubble-minus:before {
    content: "\eaed";
}
.icon-bubble-notification2:before {
    content: "\eaee";
}
.icon-bubble-left:before {
    content: "\eaef";
}
.icon-bubble-right:before {
    content: "\eaf0";
}
.icon-bubble-first:before {
    content: "\eaf1";
}
.icon-bubble-last:before {
    content: "\eaf2";
}
.icon-bubble-reply:before {
    content: "\eaf3";
}
.icon-bubble-forward:before {
    content: "\eaf4";
}
.icon-bubble-reply2:before {
    content: "\eaf5";
}
.icon-bubble-forward2:before {
    content: "\eaf6";
}
.icon-user:before {
    content: "\eaf7";
}
.icon-users:before {
    content: "\eaf8";
}
.icon-user-plus:before {
    content: "\eaf9";
}
.icon-user-minus:before {
    content: "\eafa";
}
.icon-user-cancel:before {
    content: "\eafb";
}
.icon-user-block:before {
    content: "\eafc";
}
.icon-user-lock:before {
    content: "\eafd";
}
.icon-user-check:before {
    content: "\eafe";
}
.icon-users2:before {
    content: "\eaff";
}
.icon-user2:before {
    content: "\eb00";
}
.icon-users3:before {
    content: "\eb01";
}
.icon-user-plus2:before {
    content: "\eb02";
}
.icon-user-minus2:before {
    content: "\eb03";
}
.icon-user-cancel2:before {
    content: "\eb04";
}
.icon-user-block2:before {
    content: "\eb05";
}
.icon-user-lock2:before {
    content: "\eb06";
}
.icon-user-check2:before {
    content: "\eb07";
}
.icon-user3:before {
    content: "\eb08";
}
.icon-user4:before {
    content: "\eb09";
}
.icon-user5:before {
    content: "\eb0a";
}
.icon-user6:before {
    content: "\eb0b";
}
.icon-users4:before {
    content: "\eb0c";
}
.icon-user-tie:before {
    content: "\eb0d";
}
.icon-user7:before {
    content: "\eb0e";
}
.icon-users5:before {
    content: "\eb0f";
}
.icon-collaboration:before {
    content: "\eb10";
}
.icon-vcard:before {
    content: "\eb11";
}
.icon-hat:before {
    content: "\eb12";
}
.icon-bowtie:before {
    content: "\eb13";
}
.icon-tie:before {
    content: "\eb14";
}
.icon-hanger:before {
    content: "\eb15";
}
.icon-tshirt:before {
    content: "\eb16";
}
.icon-pants:before {
    content: "\eb17";
}
.icon-shorts:before {
    content: "\eb18";
}
.icon-sock:before {
    content: "\eb19";
}
.icon-laundry:before {
    content: "\eb1a";
}
.icon-quotes-left:before {
    content: "\eb1b";
}
.icon-quotes-right:before {
    content: "\eb1c";
}
.icon-quotes-left2:before {
    content: "\eb1d";
}
.icon-quotes-right2:before {
    content: "\eb1e";
}
.icon-hour-glass:before {
    content: "\eb1f";
}
.icon-hour-glass2:before {
    content: "\eb20";
}
.icon-hour-glass3:before {
    content: "\eb21";
}
.icon-spinner:before {
    content: "\eb22";
}
.icon-spinner2:before {
    content: "\eb23";
}
.icon-spinner3:before {
    content: "\eb24";
}
.icon-spinner4:before {
    content: "\eb25";
}
.icon-spinner5:before {
    content: "\eb26";
}
.icon-spinner6:before {
    content: "\eb27";
}
.icon-spinner7:before {
    content: "\eb28";
}
.icon-spinner8:before {
    content: "\eb29";
}
.icon-spinner9:before {
    content: "\eb2a";
}
.icon-spinner10:before {
    content: "\eb2b";
}
.icon-spinner11:before {
    content: "\eb2c";
}
.icon-microscope:before {
    content: "\eb2d";
}
.icon-binoculars:before {
    content: "\eb2e";
}
.icon-binoculars2:before {
    content: "\eb2f";
}
.icon-search:before {
    content: "\eb30";
}
.icon-zoom-in:before {
    content: "\eb31";
}
.icon-zoom-out:before {
    content: "\eb32";
}
.icon-search2:before {
    content: "\eb33";
}
.icon-zoom-in2:before {
    content: "\eb34";
}
.icon-zoom-out2:before {
    content: "\eb35";
}
.icon-search3:before {
    content: "\eb36";
}
.icon-search4:before {
    content: "\eb37";
}
.icon-zoom-in3:before {
    content: "\eb38";
}
.icon-zoom-out3:before {
    content: "\eb39";
}
.icon-search5:before {
    content: "\eb3a";
}
.icon-search6:before {
    content: "\eb3b";
}
.icon-enlarge:before {
    content: "\eb3c";
}
.icon-shrink:before {
    content: "\eb3d";
}
.icon-enlarge2:before {
    content: "\eb3e";
}
.icon-shrink2:before {
    content: "\eb3f";
}
.icon-enlarge3:before {
    content: "\eb40";
}
.icon-shrink3:before {
    content: "\eb41";
}
.icon-enlarge4:before {
    content: "\eb42";
}
.icon-shrink4:before {
    content: "\eb43";
}
.icon-enlarge5:before {
    content: "\eb44";
}
.icon-shrink5:before {
    content: "\eb45";
}
.icon-enlarge6:before {
    content: "\eb46";
}
.icon-shrink6:before {
    content: "\eb47";
}
.icon-enlarge7:before {
    content: "\eb48";
}
.icon-shrink7:before {
    content: "\eb49";
}
.icon-key:before {
    content: "\eb4a";
}
.icon-key2:before {
    content: "\eb4b";
}
.icon-key3:before {
    content: "\eb4c";
}
.icon-key4:before {
    content: "\eb4d";
}
.icon-key5:before {
    content: "\eb4e";
}
.icon-keyhole:before {
    content: "\eb4f";
}
.icon-lock:before {
    content: "\eb50";
}
.icon-lock2:before {
    content: "\eb51";
}
.icon-lock3:before {
    content: "\eb52";
}
.icon-lock4:before {
    content: "\eb53";
}
.icon-unlocked:before {
    content: "\eb54";
}
.icon-lock5:before {
    content: "\eb55";
}
.icon-unlocked2:before {
    content: "\eb56";
}
.icon-safe:before {
    content: "\eb57";
}
.icon-wrench:before {
    content: "\eb58";
}
.icon-wrench2:before {
    content: "\eb59";
}
.icon-wrench3:before {
    content: "\eb5a";
}
.icon-equalizer:before {
    content: "\eb5b";
}
.icon-equalizer2:before {
    content: "\eb5c";
}
.icon-equalizer3:before {
    content: "\eb5d";
}
.icon-equalizer4:before {
    content: "\eb5e";
}
.icon-cog:before {
    content: "\eb5f";
}
.icon-cogs:before {
    content: "\eb60";
}
.icon-cog2:before {
    content: "\eb61";
}
.icon-cog3:before {
    content: "\eb62";
}
.icon-cog4:before {
    content: "\eb63";
}
.icon-cog5:before {
    content: "\eb64";
}
.icon-cog6:before {
    content: "\eb65";
}
.icon-cog7:before {
    content: "\eb66";
}
.icon-factory:before {
    content: "\eb67";
}
.icon-hammer:before {
    content: "\eb68";
}
.icon-hammer-wrench:before {
    content: "\eb69";
}
.icon-knife:before {
    content: "\eb6a";
}
.icon-screwdriver:before {
    content: "\eb6b";
}
.icon-screwdriver2:before {
    content: "\eb6c";
}
.icon-magic-wand:before {
    content: "\eb6d";
}
.icon-magic-wand2:before {
    content: "\eb6e";
}
.icon-pulse:before {
    content: "\eb6f";
}
.icon-pulse2:before {
    content: "\eb70";
}
.icon-aid-kit:before {
    content: "\eb71";
}
.icon-aid-kit2:before {
    content: "\eb72";
}
.icon-stethoscope:before {
    content: "\eb73";
}
.icon-ambulance:before {
    content: "\eb74";
}
.icon-alarm2:before {
    content: "\eb75";
}
.icon-pandage:before {
    content: "\eb76";
}
.icon-pandage2:before {
    content: "\eb77";
}
.icon-bug:before {
    content: "\eb78";
}
.icon-bug2:before {
    content: "\eb79";
}
.icon-syringe:before {
    content: "\eb7a";
}
.icon-syringe2:before {
    content: "\eb7b";
}
.icon-pill:before {
    content: "\eb7c";
}
.icon-construction:before {
    content: "\eb7d";
}
.icon-traffic-cone:before {
    content: "\eb7e";
}
.icon-traffic-lights:before {
    content: "\eb7f";
}
.icon-pie-chart:before {
    content: "\eb80";
}
.icon-pie-chart2:before {
    content: "\eb81";
}
.icon-pie-chart3:before {
    content: "\eb82";
}
.icon-pie-chart4:before {
    content: "\eb83";
}
.icon-pie-chart5:before {
    content: "\eb84";
}
.icon-pie-chart6:before {
    content: "\eb85";
}
.icon-pie-chart7:before {
    content: "\eb86";
}
.icon-stats-dots:before {
    content: "\eb87";
}
.icon-stats-bars:before {
    content: "\eb88";
}
.icon-pie-chart8:before {
    content: "\eb89";
}
.icon-stats-bars2:before {
    content: "\eb8a";
}
.icon-stats-bars3:before {
    content: "\eb8b";
}
.icon-stats-bars4:before {
    content: "\eb8c";
}
.icon-stats-bars5:before {
    content: "\eb8d";
}
.icon-stats-bars6:before {
    content: "\eb8e";
}
.icon-chart:before {
    content: "\eb8f";
}
.icon-stats-growth:before {
    content: "\eb90";
}
.icon-stats-decline:before {
    content: "\eb91";
}
.icon-stats-growth2:before {
    content: "\eb92";
}
.icon-stats-decline2:before {
    content: "\eb93";
}
.icon-stairs-up:before {
    content: "\eb94";
}
.icon-stairs-down:before {
    content: "\eb95";
}
.icon-stairs:before {
    content: "\eb96";
}
.icon-stairs2:before {
    content: "\eb97";
}
.icon-ladder:before {
    content: "\eb98";
}
.icon-rating:before {
    content: "\eb99";
}
.icon-rating2:before {
    content: "\eb9a";
}
.icon-rating3:before {
    content: "\eb9b";
}
.icon-cake:before {
    content: "\eb9c";
}
.icon-podium:before {
    content: "\eb9d";
}
.icon-stars:before {
    content: "\eb9e";
}
.icon-medal-star:before {
    content: "\eb9f";
}
.icon-medal:before {
    content: "\eba0";
}
.icon-medal2:before {
    content: "\eba1";
}
.icon-medal-first:before {
    content: "\eba2";
}
.icon-medal-second:before {
    content: "\eba3";
}
.icon-medal-third:before {
    content: "\eba4";
}
.icon-crown:before {
    content: "\eba5";
}
.icon-trophy:before {
    content: "\eba6";
}
.icon-trophy2:before {
    content: "\eba7";
}
.icon-trophy3:before {
    content: "\eba8";
}
.icon-diamond:before {
    content: "\eba9";
}
.icon-diamond2:before {
    content: "\ebaa";
}
.icon-trophy4:before {
    content: "\ebab";
}
.icon-gift:before {
    content: "\ebac";
}
.icon-gift2:before {
    content: "\ebad";
}
.icon-balloon:before {
    content: "\ebae";
}
.icon-pipe:before {
    content: "\ebaf";
}
.icon-mustache:before {
    content: "\ebb0";
}
.icon-mustache2:before {
    content: "\ebb1";
}
.icon-glass:before {
    content: "\ebb2";
}
.icon-glass2:before {
    content: "\ebb3";
}
.icon-glass3:before {
    content: "\ebb4";
}
.icon-glass4:before {
    content: "\ebb5";
}
.icon-bottle:before {
    content: "\ebb6";
}
.icon-bottle2:before {
    content: "\ebb7";
}
.icon-bottle3:before {
    content: "\ebb8";
}
.icon-bottle4:before {
    content: "\ebb9";
}
.icon-mug:before {
    content: "\ebba";
}
.icon-fork-knife:before {
    content: "\ebbb";
}
.icon-spoon-knife:before {
    content: "\ebbc";
}
.icon-cook:before {
    content: "\ebbd";
}
.icon-plate:before {
    content: "\ebbe";
}
.icon-steak:before {
    content: "\ebbf";
}
.icon-fish:before {
    content: "\ebc0";
}
.icon-chicken:before {
    content: "\ebc1";
}
.icon-hamburger:before {
    content: "\ebc2";
}
.icon-pizza:before {
    content: "\ebc3";
}
.icon-cheese:before {
    content: "\ebc4";
}
.icon-bread:before {
    content: "\ebc5";
}
.icon-icecream:before {
    content: "\ebc6";
}
.icon-milk:before {
    content: "\ebc7";
}
.icon-cup:before {
    content: "\ebc8";
}
.icon-cup2:before {
    content: "\ebc9";
}
.icon-coffee-bean:before {
    content: "\ebca";
}
.icon-coffee:before {
    content: "\ebcb";
}
.icon-teapot:before {
    content: "\ebcc";
}
.icon-leaf:before {
    content: "\ebcd";
}
.icon-leaf2:before {
    content: "\ebce";
}
.icon-apple:before {
    content: "\ebcf";
}
.icon-grapes:before {
    content: "\ebd0";
}
.icon-cherry:before {
    content: "\ebd1";
}
.icon-tree:before {
    content: "\ebd2";
}
.icon-tree2:before {
    content: "\ebd3";
}
.icon-tree3:before {
    content: "\ebd4";
}
.icon-tree4:before {
    content: "\ebd5";
}
.icon-cactus:before {
    content: "\ebd6";
}
.icon-mountains:before {
    content: "\ebd7";
}
.icon-paw:before {
    content: "\ebd8";
}
.icon-footprint:before {
    content: "\ebd9";
}
.icon-tent:before {
    content: "\ebda";
}
.icon-flower:before {
    content: "\ebdb";
}
.icon-flower2:before {
    content: "\ebdc";
}
.icon-rocket:before {
    content: "\ebdd";
}
.icon-meter:before {
    content: "\ebde";
}
.icon-meter2:before {
    content: "\ebdf";
}
.icon-meter-slow:before {
    content: "\ebe0";
}
.icon-meter-medium:before {
    content: "\ebe1";
}
.icon-meter-fast:before {
    content: "\ebe2";
}
.icon-meter3:before {
    content: "\ebe3";
}
.icon-hammer2:before {
    content: "\ebe4";
}
.icon-balance:before {
    content: "\ebe5";
}
.icon-scale:before {
    content: "\ebe6";
}
.icon-bomb:before {
    content: "\ebe7";
}
.icon-fire:before {
    content: "\ebe8";
}
.icon-fire2:before {
    content: "\ebe9";
}
.icon-lab:before {
    content: "\ebea";
}
.icon-atom:before {
    content: "\ebeb";
}
.icon-atom2:before {
    content: "\ebec";
}
.icon-magnet:before {
    content: "\ebed";
}
.icon-magnet2:before {
    content: "\ebee";
}
.icon-dumbbell:before {
    content: "\ebef";
}
.icon-skull:before {
    content: "\ebf0";
}
.icon-skull2:before {
    content: "\ebf1";
}
.icon-lamp:before {
    content: "\ebf2";
}
.icon-lamp2:before {
    content: "\ebf3";
}
.icon-lamp3:before {
    content: "\ebf4";
}
.icon-spotlight:before {
    content: "\ebf5";
}
.icon-lamp4:before {
    content: "\ebf6";
}
.icon-lamp5:before {
    content: "\ebf7";
}
.icon-lamp6:before {
    content: "\ebf8";
}
.icon-lamp7:before {
    content: "\ebf9";
}
.icon-lamp8:before {
    content: "\ebfa";
}
.icon-broom:before {
    content: "\ebfb";
}
.icon-toiletpaper:before {
    content: "\ebfc";
}
.icon-bin:before {
    content: "\ebfd";
}
.icon-bin2:before {
    content: "\ebfe";
}
.icon-bin3:before {
    content: "\ebff";
}
.icon-bin4:before {
    content: "\ec00";
}
.icon-bin5:before {
    content: "\ec01";
}
.icon-briefcase:before {
    content: "\ec02";
}
.icon-briefcase2:before {
    content: "\ec03";
}
.icon-briefcase3:before {
    content: "\ec04";
}
.icon-airplane:before {
    content: "\ec05";
}
.icon-airplane2:before {
    content: "\ec06";
}
.icon-airplane3:before {
    content: "\ec07";
}
.icon-airplane4:before {
    content: "\ec08";
}
.icon-paperplane:before {
    content: "\ec09";
}
.icon-car:before {
    content: "\ec0a";
}
.icon-steering-wheel:before {
    content: "\ec0b";
}
.icon-car2:before {
    content: "\ec0c";
}
.icon-gas:before {
    content: "\ec0d";
}
.icon-bus:before {
    content: "\ec0e";
}
.icon-truck:before {
    content: "\ec0f";
}
.icon-bike:before {
    content: "\ec10";
}
.icon-road:before {
    content: "\ec11";
}
.icon-train:before {
    content: "\ec12";
}
.icon-train2:before {
    content: "\ec13";
}
.icon-ship:before {
    content: "\ec14";
}
.icon-boat:before {
    content: "\ec15";
}
.icon-chopper:before {
    content: "\ec16";
}
.icon-chopper2:before {
    content: "\ec17";
}
.icon-cube:before {
    content: "\ec18";
}
.icon-cube2:before {
    content: "\ec19";
}
.icon-cube3:before {
    content: "\ec1a";
}
.icon-cube4:before {
    content: "\ec1b";
}
.icon-pyramid:before {
    content: "\ec1c";
}
.icon-pyramid2:before {
    content: "\ec1d";
}
.icon-package:before {
    content: "\ec1e";
}
.icon-puzzle:before {
    content: "\ec1f";
}
.icon-puzzle2:before {
    content: "\ec20";
}
.icon-puzzle3:before {
    content: "\ec21";
}
.icon-puzzle4:before {
    content: "\ec22";
}
.icon-glasses-3d:before {
    content: "\ec23";
}
.icon-glasses-3d2:before {
    content: "\ec24";
}
.icon-glasses:before {
    content: "\ec25";
}
.icon-glasses2:before {
    content: "\ec26";
}
.icon-brain:before {
    content: "\ec27";
}
.icon-accessibility:before {
    content: "\ec28";
}
.icon-accessibility2:before {
    content: "\ec29";
}
.icon-strategy:before {
    content: "\ec2a";
}
.icon-target:before {
    content: "\ec2b";
}
.icon-target2:before {
    content: "\ec2c";
}
.icon-target3:before {
    content: "\ec2d";
}
.icon-ammo:before {
    content: "\ec2e";
}
.icon-gun:before {
    content: "\ec2f";
}
.icon-gun-forbidden:before {
    content: "\ec30";
}
.icon-shield:before {
    content: "\ec31";
}
.icon-shield-check:before {
    content: "\ec32";
}
.icon-shield-notice:before {
    content: "\ec33";
}
.icon-shield2:before {
    content: "\ec34";
}
.icon-shield3:before {
    content: "\ec35";
}
.icon-shield4:before {
    content: "\ec36";
}
.icon-soccer:before {
    content: "\ec37";
}
.icon-football:before {
    content: "\ec38";
}
.icon-archery:before {
    content: "\ec39";
}
.icon-bow:before {
    content: "\ec3a";
}
.icon-sword:before {
    content: "\ec3b";
}
.icon-tennis:before {
    content: "\ec3c";
}
.icon-pingpong:before {
    content: "\ec3d";
}
.icon-baseball:before {
    content: "\ec3e";
}
.icon-basketball:before {
    content: "\ec3f";
}
.icon-golf:before {
    content: "\ec40";
}
.icon-golf2:before {
    content: "\ec41";
}
.icon-hockey:before {
    content: "\ec42";
}
.icon-racing:before {
    content: "\ec43";
}
.icon-finish:before {
    content: "\ec44";
}
.icon-eight-ball:before {
    content: "\ec45";
}
.icon-bowling:before {
    content: "\ec46";
}
.icon-bowling2:before {
    content: "\ec47";
}
.icon-power:before {
    content: "\ec48";
}
.icon-power2:before {
    content: "\ec49";
}
.icon-power3:before {
    content: "\ec4a";
}
.icon-switch:before {
    content: "\ec4b";
}
.icon-switch2:before {
    content: "\ec4c";
}
.icon-power-cord:before {
    content: "\ec4d";
}
.icon-power-cord2:before {
    content: "\ec4e";
}
.icon-outlet:before {
    content: "\ec4f";
}
.icon-clipboard:before {
    content: "\ec50";
}
.icon-clipboard2:before {
    content: "\ec51";
}
.icon-clipboard3:before {
    content: "\ec52";
}
.icon-clipboard4:before {
    content: "\ec53";
}
.icon-clipboard5:before {
    content: "\ec54";
}
.icon-clipboard6:before {
    content: "\ec55";
}
.icon-playlist:before {
    content: "\ec56";
}
.icon-playlist-add:before {
    content: "\ec57";
}
.icon-list-numbered:before {
    content: "\ec58";
}
.icon-list:before {
    content: "\ec59";
}
.icon-list2:before {
    content: "\ec5a";
}
.icon-more:before {
    content: "\ec5b";
}
.icon-more2:before {
    content: "\ec5c";
}
.icon-grid:before {
    content: "\ec5d";
}
.icon-grid2:before {
    content: "\ec5e";
}
.icon-grid3:before {
    content: "\ec5f";
}
.icon-grid4:before {
    content: "\ec60";
}
.icon-grid5:before {
    content: "\ec61";
}
.icon-grid6:before {
    content: "\ec62";
}
.icon-grid7:before {
    content: "\ec63";
}
.icon-tree5:before {
    content: "\ec64";
}
.icon-tree6:before {
    content: "\ec65";
}
.icon-tree7:before {
    content: "\ec66";
}
.icon-lan:before {
    content: "\ec67";
}
.icon-lan2:before {
    content: "\ec68";
}
.icon-lan3:before {
    content: "\ec69";
}
.icon-menu:before {
    content: "\ec6a";
}
.icon-circle-small:before {
    content: "\ec6b";
}
.icon-menu2:before {
    content: "\ec6c";
}
.icon-menu3:before {
    content: "\ec6d";
}
.icon-menu4:before {
    content: "\ec6e";
}
.icon-menu5:before {
    content: "\ec6f";
}
.icon-menu6:before {
    content: "\ec70";
}
.icon-menu7:before {
    content: "\ec71";
}
.icon-menu8:before {
    content: "\ec72";
}
.icon-menu9:before {
    content: "\ec73";
}
.icon-menu10:before {
    content: "\ec74";
}
.icon-cloud:before {
    content: "\ec75";
}
.icon-cloud-download:before {
    content: "\ec76";
}
.icon-cloud-upload:before {
    content: "\ec77";
}
.icon-cloud-check:before {
    content: "\ec78";
}
.icon-cloud2:before {
    content: "\ec79";
}
.icon-cloud-download2:before {
    content: "\ec7a";
}
.icon-cloud-upload2:before {
    content: "\ec7b";
}
.icon-cloud-check2:before {
    content: "\ec7c";
}
.icon-download2:before {
    content: "\ec7d";
}
.icon-download3:before {
    content: "\ec7e";
}
.icon-upload2:before {
    content: "\ec7f";
}
.icon-upload3:before {
    content: "\ec80";
}
.icon-import:before {
    content: "\ec81";
}
.icon-import2:before {
    content: "\ec82";
}
.icon-download4:before {
    content: "\ec83";
}
.icon-upload4:before {
    content: "\ec84";
}
.icon-download5:before {
    content: "\ec85";
}
.icon-upload5:before {
    content: "\ec86";
}
.icon-download6:before {
    content: "\ec87";
}
.icon-upload6:before {
    content: "\ec88";
}
.icon-download7:before {
    content: "\ec89";
}
.icon-upload7:before {
    content: "\ec8a";
}
.icon-download8:before {
    content: "\ec8b";
}
.icon-upload8:before {
    content: "\ec8c";
}
.icon-download9:before {
    content: "\ec8d";
}
.icon-upload9:before {
    content: "\ec8e";
}
.icon-download10:before {
    content: "\ec8f";
}
.icon-upload10:before {
    content: "\ec90";
}
.icon-sphere:before {
    content: "\ec91";
}
.icon-sphere2:before {
    content: "\ec92";
}
.icon-sphere3:before {
    content: "\ec93";
}
.icon-planet:before {
    content: "\ec94";
}
.icon-planet2:before {
    content: "\ec95";
}
.icon-earth:before {
    content: "\ec96";
}
.icon-earth2:before {
    content: "\ec97";
}
.icon-earth3:before {
    content: "\ec98";
}
.icon-link:before {
    content: "\ec99";
}
.icon-unlink:before {
    content: "\ec9a";
}
.icon-link2:before {
    content: "\ec9b";
}
.icon-unlink2:before {
    content: "\ec9c";
}
.icon-link3:before {
    content: "\ec9d";
}
.icon-unlink3:before {
    content: "\ec9e";
}
.icon-link4:before {
    content: "\ec9f";
}
.icon-unlink4:before {
    content: "\eca0";
}
.icon-link5:before {
    content: "\eca1";
}
.icon-unlink5:before {
    content: "\eca2";
}
.icon-anchor:before {
    content: "\eca3";
}
.icon-flag:before {
    content: "\eca4";
}
.icon-flag2:before {
    content: "\eca5";
}
.icon-flag3:before {
    content: "\eca6";
}
.icon-flag4:before {
    content: "\eca7";
}
.icon-flag5:before {
    content: "\eca8";
}
.icon-flag6:before {
    content: "\eca9";
}
.icon-flag7:before {
    content: "\ecaa";
}
.icon-flag8:before {
    content: "\ecab";
}
.icon-attachment:before {
    content: "\ecac";
}
.icon-attachment2:before {
    content: "\ecad";
}
.icon-eye:before {
    content: "\ecae";
}
.icon-eye-plus:before {
    content: "\ecaf";
}
.icon-eye-minus:before {
    content: "\ecb0";
}
.icon-eye-blocked:before {
    content: "\ecb1";
}
.icon-eye2:before {
    content: "\ecb2";
}
.icon-eye-blocked2:before {
    content: "\ecb3";
}
.icon-eye3:before {
    content: "\ecb4";
}
.icon-eye-blocked3:before {
    content: "\ecb5";
}
.icon-eye4:before {
    content: "\ecb6";
}
.icon-bookmark2:before {
    content: "\ecb7";
}
.icon-bookmark3:before {
    content: "\ecb8";
}
.icon-bookmarks:before {
    content: "\ecb9";
}
.icon-bookmark4:before {
    content: "\ecba";
}
.icon-spotlight2:before {
    content: "\ecbb";
}
.icon-starburst:before {
    content: "\ecbc";
}
.icon-snowflake:before {
    content: "\ecbd";
}
.icon-temperature:before {
    content: "\ecbe";
}
.icon-temperature2:before {
    content: "\ecbf";
}
.icon-weather-lightning:before {
    content: "\ecc0";
}
.icon-weather-lightning2:before {
    content: "\ecc1";
}
.icon-weather-rain:before {
    content: "\ecc2";
}
.icon-weather-rain2:before {
    content: "\ecc3";
}
.icon-weather-snow:before {
    content: "\ecc4";
}
.icon-weather-snow2:before {
    content: "\ecc5";
}
.icon-weather-cloud-wind:before {
    content: "\ecc6";
}
.icon-weather-cloud-wind2:before {
    content: "\ecc7";
}
.icon-weather-cloud-sun:before {
    content: "\ecc8";
}
.icon-weather-cloud-sun2:before {
    content: "\ecc9";
}
.icon-weather-cloudy:before {
    content: "\ecca";
}
.icon-weather-cloudy2:before {
    content: "\eccb";
}
.icon-weather-sun-wind:before {
    content: "\eccc";
}
.icon-weather-sun-wind2:before {
    content: "\eccd";
}
.icon-sun:before {
    content: "\ecce";
}
.icon-sun2:before {
    content: "\eccf";
}
.icon-moon:before {
    content: "\ecd0";
}
.icon-day-night:before {
    content: "\ecd1";
}
.icon-day-night2:before {
    content: "\ecd2";
}
.icon-weather-windy:before {
    content: "\ecd3";
}
.icon-fan:before {
    content: "\ecd4";
}
.icon-umbrella:before {
    content: "\ecd5";
}
.icon-sun3:before {
    content: "\ecd6";
}
.icon-contrast:before {
    content: "\ecd7";
}
.icon-brightness-contrast:before {
    content: "\ecd8";
}
.icon-brightness-high:before {
    content: "\ecd9";
}
.icon-brightness-medium:before {
    content: "\ecda";
}
.icon-brightness-low:before {
    content: "\ecdb";
}
.icon-bed:before {
    content: "\ecdc";
}
.icon-bed2:before {
    content: "\ecdd";
}
.icon-furniture:before {
    content: "\ecde";
}
.icon-chair:before {
    content: "\ecdf";
}
.icon-star-empty:before {
    content: "\ece0";
}
.icon-star-empty2:before {
    content: "\ece1";
}
.icon-star-full:before {
    content: "\ece2";
}
.icon-star-empty3:before {
    content: "\ece3";
}
.icon-star-half:before {
    content: "\ece4";
}
.icon-star-full2:before {
    content: "\ece5";
}
.icon-star:before {
    content: "\ece6";
}
.icon-heart:before {
    content: "\ece7";
}
.icon-heart2:before {
    content: "\ece8";
}
.icon-heart3:before {
    content: "\ece9";
}
.icon-heart4:before {
    content: "\ecea";
}
.icon-heart-broken:before {
    content: "\eceb";
}
.icon-heart5:before {
    content: "\ecec";
}
.icon-heart6:before {
    content: "\eced";
}
.icon-heart-broken2:before {
    content: "\ecee";
}
.icon-heart7:before {
    content: "\ecef";
}
.icon-heart8:before {
    content: "\ecf0";
}
.icon-heart-broken3:before {
    content: "\ecf1";
}
.icon-lips:before {
    content: "\ecf2";
}
.icon-lips2:before {
    content: "\ecf3";
}
.icon-thumbs-up:before {
    content: "\ecf4";
}
.icon-thumbs-up2:before {
    content: "\ecf5";
}
.icon-thumbs-down:before {
    content: "\ecf6";
}
.icon-thumbs-down2:before {
    content: "\ecf7";
}
.icon-thumbs-up3:before {
    content: "\ecf8";
}
.icon-thumbs-down3:before {
    content: "\ecf9";
}
.icon-height:before {
    content: "\ecfa";
}
.icon-man:before {
    content: "\ecfb";
}
.icon-woman:before {
    content: "\ecfc";
}
.icon-man-woman:before {
    content: "\ecfd";
}
.icon-male:before {
    content: "\ecfe";
}
.icon-female:before {
    content: "\ecff";
}
.icon-peace:before {
    content: "\ed00";
}
.icon-yin-yang:before {
    content: "\ed01";
}
.icon-happy:before {
    content: "\ed02";
}
.icon-happy2:before {
    content: "\ed03";
}
.icon-smile:before {
    content: "\ed04";
}
.icon-smile2:before {
    content: "\ed05";
}
.icon-tongue:before {
    content: "\ed06";
}
.icon-tongue2:before {
    content: "\ed07";
}
.icon-sad:before {
    content: "\ed08";
}
.icon-sad2:before {
    content: "\ed09";
}
.icon-wink:before {
    content: "\ed0a";
}
.icon-wink2:before {
    content: "\ed0b";
}
.icon-grin:before {
    content: "\ed0c";
}
.icon-grin2:before {
    content: "\ed0d";
}
.icon-cool:before {
    content: "\ed0e";
}
.icon-cool2:before {
    content: "\ed0f";
}
.icon-angry:before {
    content: "\ed10";
}
.icon-angry2:before {
    content: "\ed11";
}
.icon-evil:before {
    content: "\ed12";
}
.icon-evil2:before {
    content: "\ed13";
}
.icon-shocked:before {
    content: "\ed14";
}
.icon-shocked2:before {
    content: "\ed15";
}
.icon-baffled:before {
    content: "\ed16";
}
.icon-baffled2:before {
    content: "\ed17";
}
.icon-confused:before {
    content: "\ed18";
}
.icon-confused2:before {
    content: "\ed19";
}
.icon-neutral:before {
    content: "\ed1a";
}
.icon-neutral2:before {
    content: "\ed1b";
}
.icon-hipster:before {
    content: "\ed1c";
}
.icon-hipster2:before {
    content: "\ed1d";
}
.icon-wondering:before {
    content: "\ed1e";
}
.icon-wondering2:before {
    content: "\ed1f";
}
.icon-sleepy:before {
    content: "\ed20";
}
.icon-sleepy2:before {
    content: "\ed21";
}
.icon-frustrated:before {
    content: "\ed22";
}
.icon-frustrated2:before {
    content: "\ed23";
}
.icon-crying:before {
    content: "\ed24";
}
.icon-crying2:before {
    content: "\ed25";
}
.icon-cursor:before {
    content: "\ed26";
}
.icon-cursor2:before {
    content: "\ed27";
}
.icon-lasso:before {
    content: "\ed28";
}
.icon-lasso2:before {
    content: "\ed29";
}
.icon-select:before {
    content: "\ed2a";
}
.icon-select2:before {
    content: "\ed2b";
}
.icon-point-up:before {
    content: "\ed2c";
}
.icon-point-right:before {
    content: "\ed2d";
}
.icon-point-down:before {
    content: "\ed2e";
}
.icon-point-left:before {
    content: "\ed2f";
}
.icon-pointer:before {
    content: "\ed30";
}
.icon-reminder:before {
    content: "\ed31";
}
.icon-drag-left-right:before {
    content: "\ed32";
}
.icon-drag-left:before {
    content: "\ed33";
}
.icon-drag-right:before {
    content: "\ed34";
}
.icon-touch:before {
    content: "\ed35";
}
.icon-multitouch:before {
    content: "\ed36";
}
.icon-touch-zoom:before {
    content: "\ed37";
}
.icon-touch-pinch:before {
    content: "\ed38";
}
.icon-hand:before {
    content: "\ed39";
}
.icon-grab:before {
    content: "\ed3a";
}
.icon-stack-empty:before {
    content: "\ed3b";
}
.icon-stack-plus:before {
    content: "\ed3c";
}
.icon-stack-minus:before {
    content: "\ed3d";
}
.icon-stack-star:before {
    content: "\ed3e";
}
.icon-stack-picture:before {
    content: "\ed3f";
}
.icon-stack-down:before {
    content: "\ed40";
}
.icon-stack-up:before {
    content: "\ed41";
}
.icon-stack-cancel:before {
    content: "\ed42";
}
.icon-stack-check:before {
    content: "\ed43";
}
.icon-stack-text:before {
    content: "\ed44";
}
.icon-stack-clubs:before {
    content: "\ed45";
}
.icon-stack-spades:before {
    content: "\ed46";
}
.icon-stack-hearts:before {
    content: "\ed47";
}
.icon-stack-diamonds:before {
    content: "\ed48";
}
.icon-stack-user:before {
    content: "\ed49";
}
.icon-stack4:before {
    content: "\ed4a";
}
.icon-stack-music:before {
    content: "\ed4b";
}
.icon-stack-play:before {
    content: "\ed4c";
}
.icon-move:before {
    content: "\ed4d";
}
.icon-dots:before {
    content: "\ed4e";
}
.icon-warning:before {
    content: "\ed4f";
}
.icon-warning2:before {
    content: "\ed50";
}
.icon-notification:before {
    content: "\ed51";
}
.icon-notification2:before {
    content: "\ed52";
}
.icon-question:before {
    content: "\ed53";
}
.icon-question2:before {
    content: "\ed54";
}
.icon-question3:before {
    content: "\ed55";
}
.icon-question4:before {
    content: "\ed56";
}
.icon-question5:before {
    content: "\ed57";
}
.icon-question6:before {
    content: "\ed58";
}
.icon-plus:before {
    content: "\ed59";
}
.icon-minus:before {
    content: "\ed5a";
}
.icon-plus2:before {
    content: "\ed5b";
}
.icon-minus2:before {
    content: "\ed5c";
}
.icon-plus3:before {
    content: "\ed5d";
}
.icon-minus3:before {
    content: "\ed5e";
}
.icon-plus-circle:before {
    content: "\ed5f";
}
.icon-minus-circle:before {
    content: "\ed60";
}
.icon-plus-circle2:before {
    content: "\ed61";
}
.icon-minus-circle2:before {
    content: "\ed62";
}
.icon-info:before {
    content: "\ed63";
}
.icon-info2:before {
    content: "\ed64";
}
.icon-cancel-circle:before {
    content: "\ed65";
}
.icon-cancel-circle2:before {
    content: "\ed66";
}
.icon-blocked:before {
    content: "\ed67";
}
.icon-cancel-square:before {
    content: "\ed68";
}
.icon-cancel-square2:before {
    content: "\ed69";
}
.icon-cancel:before {
    content: "\ed6a";
}
.icon-spam:before {
    content: "\ed6b";
}
.icon-cross:before {
    content: "\ed6c";
}
.icon-cross2:before {
    content: "\ed6d";
}
.icon-cross3:before {
    content: "\ed6e";
}
.icon-checkmark:before {
    content: "\ed6f";
}
.icon-checkmark2:before {
    content: "\ed70";
}
.icon-checkmark3:before {
    content: "\ed71";
}
.icon-checkmark4:before {
    content: "\ed72";
}
.icon-checkmark5:before {
    content: "\ed73";
}
.icon-spell-check:before {
    content: "\ed74";
}
.icon-spell-check2:before {
    content: "\ed75";
}
.icon-enter:before {
    content: "\ed76";
}
.icon-exit:before {
    content: "\ed77";
}
.icon-enter2:before {
    content: "\ed78";
}
.icon-exit2:before {
    content: "\ed79";
}
.icon-enter3:before {
    content: "\ed7a";
}
.icon-exit3:before {
    content: "\ed7b";
}
.icon-wall:before {
    content: "\ed7c";
}
.icon-fence:before {
    content: "\ed7d";
}
.icon-play3:before {
    content: "\ed7e";
}
.icon-pause:before {
    content: "\ed7f";
}
.icon-stop:before {
    content: "\ed80";
}
.icon-previous:before {
    content: "\ed81";
}
.icon-next:before {
    content: "\ed82";
}
.icon-backward:before {
    content: "\ed83";
}
.icon-forward2:before {
    content: "\ed84";
}
.icon-play4:before {
    content: "\ed85";
}
.icon-pause2:before {
    content: "\ed86";
}
.icon-stop2:before {
    content: "\ed87";
}
.icon-backward2:before {
    content: "\ed88";
}
.icon-forward3:before {
    content: "\ed89";
}
.icon-first:before {
    content: "\ed8a";
}
.icon-last:before {
    content: "\ed8b";
}
.icon-previous2:before {
    content: "\ed8c";
}
.icon-next2:before {
    content: "\ed8d";
}
.icon-eject:before {
    content: "\ed8e";
}
.icon-volume-high:before {
    content: "\ed8f";
}
.icon-volume-medium:before {
    content: "\ed90";
}
.icon-volume-low:before {
    content: "\ed91";
}
.icon-volume-mute:before {
    content: "\ed92";
}
.icon-speaker-left:before {
    content: "\ed93";
}
.icon-speaker-right:before {
    content: "\ed94";
}
.icon-volume-mute2:before {
    content: "\ed95";
}
.icon-volume-increase:before {
    content: "\ed96";
}
.icon-volume-decrease:before {
    content: "\ed97";
}
.icon-volume-high2:before {
    content: "\ed98";
}
.icon-volume-medium2:before {
    content: "\ed99";
}
.icon-volume-medium3:before {
    content: "\ed9a";
}
.icon-volume-low2:before {
    content: "\ed9b";
}
.icon-volume-mute3:before {
    content: "\ed9c";
}
.icon-volume-increase2:before {
    content: "\ed9d";
}
.icon-volume-decrease2:before {
    content: "\ed9e";
}
.icon-volume-decrease3:before {
    content: "\ed9f";
}
.icon-volume-5:before {
    content: "\eda0";
}
.icon-volume-4:before {
    content: "\eda1";
}
.icon-volume-3:before {
    content: "\eda2";
}
.icon-volume-2:before {
    content: "\eda3";
}
.icon-volume-1:before {
    content: "\eda4";
}
.icon-volume-0:before {
    content: "\eda5";
}
.icon-volume-mute4:before {
    content: "\eda6";
}
.icon-volume-mute5:before {
    content: "\eda7";
}
.icon-loop:before {
    content: "\eda8";
}
.icon-loop2:before {
    content: "\eda9";
}
.icon-loop3:before {
    content: "\edaa";
}
.icon-infinite-square:before {
    content: "\edab";
}
.icon-infinite:before {
    content: "\edac";
}
.icon-infinite2:before {
    content: "\edad";
}
.icon-loop4:before {
    content: "\edae";
}
.icon-shuffle:before {
    content: "\edaf";
}
.icon-shuffle2:before {
    content: "\edb0";
}
.icon-wave:before {
    content: "\edb1";
}
.icon-wave2:before {
    content: "\edb2";
}
.icon-split:before {
    content: "\edb3";
}
.icon-merge:before {
    content: "\edb4";
}
.icon-first2:before {
    content: "\edb5";
}
.icon-last2:before {
    content: "\edb6";
}
.icon-arrow-up:before {
    content: "\edb7";
}
.icon-arrow-up2:before {
    content: "\edb8";
}
.icon-arrow-up3:before {
    content: "\edb9";
}
.icon-arrow-up4:before {
    content: "\edba";
}
.icon-arrow-right:before {
    content: "\edbb";
}
.icon-arrow-right2:before {
    content: "\edbc";
}
.icon-arrow-right3:before {
    content: "\edbd";
}
.icon-arrow-right4:before {
    content: "\edbe";
}
.icon-arrow-down:before {
    content: "\edbf";
}
.icon-arrow-down2:before {
    content: "\edc0";
}
.icon-arrow-down3:before {
    content: "\edc1";
}
.icon-arrow-down4:before {
    content: "\edc2";
}
.icon-arrow-left:before {
    content: "\edc3";
}
.icon-arrow-left2:before {
    content: "\edc4";
}
.icon-arrow-left3:before {
    content: "\edc5";
}
.icon-arrow-left4:before {
    content: "\edc6";
}
.icon-arrow-up5:before {
    content: "\edc7";
}
.icon-arrow-right5:before {
    content: "\edc8";
}
.icon-arrow-down5:before {
    content: "\edc9";
}
.icon-arrow-left5:before {
    content: "\edca";
}
.icon-arrow-up-left:before {
    content: "\edcb";
}
.icon-arrow-up6:before {
    content: "\edcc";
}
.icon-arrow-up-right:before {
    content: "\edcd";
}
.icon-arrow-right6:before {
    content: "\edce";
}
.icon-arrow-down-right:before {
    content: "\edcf";
}
.icon-arrow-down6:before {
    content: "\edd0";
}
.icon-arrow-down-left:before {
    content: "\edd1";
}
.icon-arrow-left6:before {
    content: "\edd2";
}
.icon-arrow-up-left2:before {
    content: "\edd3";
}
.icon-arrow-up7:before {
    content: "\edd4";
}
.icon-arrow-up-right2:before {
    content: "\edd5";
}
.icon-arrow-right7:before {
    content: "\edd6";
}
.icon-arrow-down-right2:before {
    content: "\edd7";
}
.icon-arrow-down7:before {
    content: "\edd8";
}
.icon-arrow-down-left2:before {
    content: "\edd9";
}
.icon-arrow-left7:before {
    content: "\edda";
}
.icon-arrow-up-left3:before {
    content: "\eddb";
}
.icon-arrow-up8:before {
    content: "\eddc";
}
.icon-arrow-up-right3:before {
    content: "\eddd";
}
.icon-arrow-right8:before {
    content: "\edde";
}
.icon-arrow-down-right3:before {
    content: "\eddf";
}
.icon-arrow-down8:before {
    content: "\ede0";
}
.icon-arrow-down-left3:before {
    content: "\ede1";
}
.icon-arrow-left8:before {
    content: "\ede2";
}
.icon-circle-up:before {
    content: "\ede3";
}
.icon-circle-right:before {
    content: "\ede4";
}
.icon-circle-down:before {
    content: "\ede5";
}
.icon-circle-left:before {
    content: "\ede6";
}
.icon-circle-up2:before {
    content: "\ede7";
}
.icon-circle-right2:before {
    content: "\ede8";
}
.icon-circle-down2:before {
    content: "\ede9";
}
.icon-circle-left2:before {
    content: "\edea";
}
.icon-circle-up-left:before {
    content: "\edeb";
}
.icon-circle-up3:before {
    content: "\edec";
}
.icon-circle-up-right:before {
    content: "\eded";
}
.icon-circle-right3:before {
    content: "\edee";
}
.icon-circle-down-right:before {
    content: "\edef";
}
.icon-circle-down3:before {
    content: "\edf0";
}
.icon-circle-down-left:before {
    content: "\edf1";
}
.icon-circle-left3:before {
    content: "\edf2";
}
.icon-circle-up-left2:before {
    content: "\edf3";
}
.icon-circle-up4:before {
    content: "\edf4";
}
.icon-circle-up-right2:before {
    content: "\edf5";
}
.icon-circle-right4:before {
    content: "\edf6";
}
.icon-circle-down-right2:before {
    content: "\edf7";
}
.icon-circle-down4:before {
    content: "\edf8";
}
.icon-circle-down-left2:before {
    content: "\edf9";
}
.icon-circle-left4:before {
    content: "\edfa";
}
.icon-arrow-resize:before {
    content: "\edfb";
}
.icon-arrow-resize2:before {
    content: "\edfc";
}
.icon-arrow-resize3:before {
    content: "\edfd";
}
.icon-arrow-resize4:before {
    content: "\edfe";
}
.icon-arrow-resize5:before {
    content: "\edff";
}
.icon-arrow-resize6:before {
    content: "\ee00";
}
.icon-arrow-resize7:before {
    content: "\ee01";
}
.icon-arrow-resize8:before {
    content: "\ee02";
}
.icon-square-up-left:before {
    content: "\ee03";
}
.icon-square-up:before {
    content: "\ee04";
}
.icon-square-up-right:before {
    content: "\ee05";
}
.icon-square-right:before {
    content: "\ee06";
}
.icon-square-down-right:before {
    content: "\ee07";
}
.icon-square-down:before {
    content: "\ee08";
}
.icon-square-down-left:before {
    content: "\ee09";
}
.icon-square-left:before {
    content: "\ee0a";
}
.icon-arrow-up9:before {
    content: "\ee0b";
}
.icon-arrow-right9:before {
    content: "\ee0c";
}
.icon-arrow-down9:before {
    content: "\ee0d";
}
.icon-arrow-left9:before {
    content: "\ee0e";
}
.icon-arrow-up10:before {
    content: "\ee0f";
}
.icon-arrow-right10:before {
    content: "\ee10";
}
.icon-arrow-down10:before {
    content: "\ee11";
}
.icon-arrow-left10:before {
    content: "\ee12";
}
.icon-arrow-up-left4:before {
    content: "\ee13";
}
.icon-arrow-up11:before {
    content: "\ee14";
}
.icon-arrow-up-right4:before {
    content: "\ee15";
}
.icon-arrow-right11:before {
    content: "\ee16";
}
.icon-arrow-down-right4:before {
    content: "\ee17";
}
.icon-arrow-down11:before {
    content: "\ee18";
}
.icon-arrow-down-left4:before {
    content: "\ee19";
}
.icon-arrow-left11:before {
    content: "\ee1a";
}
.icon-arrow-up-left5:before {
    content: "\ee1b";
}
.icon-arrow-up12:before {
    content: "\ee1c";
}
.icon-arrow-up-right5:before {
    content: "\ee1d";
}
.icon-arrow-right12:before {
    content: "\ee1e";
}
.icon-arrow-down-right5:before {
    content: "\ee1f";
}
.icon-arrow-down12:before {
    content: "\ee20";
}
.icon-arrow-down-left5:before {
    content: "\ee21";
}
.icon-arrow-left12:before {
    content: "\ee22";
}
.icon-arrow-up13:before {
    content: "\ee23";
}
.icon-arrow-right13:before {
    content: "\ee24";
}
.icon-arrow-down13:before {
    content: "\ee25";
}
.icon-arrow-left13:before {
    content: "\ee26";
}
.icon-arrow-up14:before {
    content: "\ee27";
}
.icon-arrow-right14:before {
    content: "\ee28";
}
.icon-arrow-down14:before {
    content: "\ee29";
}
.icon-arrow-left14:before {
    content: "\ee2a";
}
.icon-circle-up5:before {
    content: "\ee2b";
}
.icon-circle-right5:before {
    content: "\ee2c";
}
.icon-circle-down5:before {
    content: "\ee2d";
}
.icon-circle-left5:before {
    content: "\ee2e";
}
.icon-circle-up6:before {
    content: "\ee2f";
}
.icon-circle-right6:before {
    content: "\ee30";
}
.icon-circle-down6:before {
    content: "\ee31";
}
.icon-circle-left6:before {
    content: "\ee32";
}
.icon-arrow-up15:before {
    content: "\ee33";
}
.icon-arrow-right15:before {
    content: "\ee34";
}
.icon-arrow-down15:before {
    content: "\ee35";
}
.icon-arrow-left15:before {
    content: "\ee36";
}
.icon-arrow-up16:before {
    content: "\ee37";
}
.icon-arrow-right16:before {
    content: "\ee38";
}
.icon-arrow-down16:before {
    content: "\ee39";
}
.icon-arrow-left16:before {
    content: "\ee3a";
}
.icon-menu-open:before {
    content: "\ee3b";
}
.icon-menu-open2:before {
    content: "\ee3c";
}
.icon-menu-close:before {
    content: "\ee3d";
}
.icon-menu-close2:before {
    content: "\ee3e";
}
.icon-enter4:before {
    content: "\ee3f";
}
.icon-enter5:before {
    content: "\ee40";
}
.icon-esc:before {
    content: "\ee41";
}
.icon-enter6:before {
    content: "\ee42";
}
.icon-backspace:before {
    content: "\ee43";
}
.icon-backspace2:before {
    content: "\ee44";
}
.icon-tab:before {
    content: "\ee45";
}
.icon-transmission:before {
    content: "\ee46";
}
.icon-transmission2:before {
    content: "\ee47";
}
.icon-sort:before {
    content: "\ee48";
}
.icon-sort2:before {
    content: "\ee49";
}
.icon-move-up:before {
    content: "\ee4a";
}
.icon-move-down:before {
    content: "\ee4b";
}
.icon-sort-alpha-asc:before {
    content: "\ee4c";
}
.icon-sort-alpha-desc:before {
    content: "\ee4d";
}
.icon-sort-numeric-asc:before {
    content: "\ee4e";
}
.icon-sort-numberic-desc:before {
    content: "\ee4f";
}
.icon-sort-amount-asc:before {
    content: "\ee50";
}
.icon-sort-amount-desc:before {
    content: "\ee51";
}
.icon-sort-time-asc:before {
    content: "\ee52";
}
.icon-sort-time-desc:before {
    content: "\ee53";
}
.icon-battery-6:before {
    content: "\ee54";
}
.icon-battery-5:before {
    content: "\ee55";
}
.icon-battery-4:before {
    content: "\ee56";
}
.icon-battery-3:before {
    content: "\ee57";
}
.icon-battery-2:before {
    content: "\ee58";
}
.icon-battery-1:before {
    content: "\ee59";
}
.icon-battery-0:before {
    content: "\ee5a";
}
.icon-battery-charging:before {
    content: "\ee5b";
}
.icon-key-keyboard:before {
    content: "\ee5c";
}
.icon-key-A:before {
    content: "\ee5d";
}
.icon-key-up:before {
    content: "\ee5e";
}
.icon-key-right:before {
    content: "\ee5f";
}
.icon-key-down:before {
    content: "\ee60";
}
.icon-key-left:before {
    content: "\ee61";
}
.icon-command:before {
    content: "\ee62";
}
.icon-shift:before {
    content: "\ee63";
}
.icon-ctrl:before {
    content: "\ee64";
}
.icon-opt:before {
    content: "\ee65";
}
.icon-checkbox-checked:before {
    content: "\ee66";
}
.icon-checkbox-unchecked:before {
    content: "\ee67";
}
.icon-checkbox-partial:before {
    content: "\ee68";
}
.icon-square:before {
    content: "\ee69";
}
.icon-triangle:before {
    content: "\ee6a";
}
.icon-triangle2:before {
    content: "\ee6b";
}
.icon-diamond3:before {
    content: "\ee6c";
}
.icon-diamond4:before {
    content: "\ee6d";
}
.icon-checkbox-checked2:before {
    content: "\ee6e";
}
.icon-checkbox-unchecked2:before {
    content: "\ee6f";
}
.icon-checkbox-partial2:before {
    content: "\ee70";
}
.icon-radio-checked:before {
    content: "\ee71";
}
.icon-radio-checked2:before {
    content: "\ee72";
}
.icon-radio-unchecked:before {
    content: "\ee73";
}
.icon-toggle-on:before {
    content: "\ee74";
}
.icon-toggle-off:before {
    content: "\ee75";
}
.icon-checkmark-circle:before {
    content: "\ee76";
}
.icon-circle:before {
    content: "\ee77";
}
.icon-circle2:before {
    content: "\ee78";
}
.icon-circles:before {
    content: "\ee79";
}
.icon-circles2:before {
    content: "\ee7a";
}
.icon-crop:before {
    content: "\ee7b";
}
.icon-crop2:before {
    content: "\ee7c";
}
.icon-make-group:before {
    content: "\ee7d";
}
.icon-ungroup:before {
    content: "\ee7e";
}
.icon-vector:before {
    content: "\ee7f";
}
.icon-vector2:before {
    content: "\ee80";
}
.icon-rulers:before {
    content: "\ee81";
}
.icon-ruler:before {
    content: "\ee82";
}
.icon-pencil-ruler:before {
    content: "\ee83";
}
.icon-scissors:before {
    content: "\ee84";
}
.icon-scissors2:before {
    content: "\ee85";
}
.icon-scissors3:before {
    content: "\ee86";
}
.icon-haircut:before {
    content: "\ee87";
}
.icon-comb:before {
    content: "\ee88";
}
.icon-filter:before {
    content: "\ee89";
}
.icon-filter2:before {
    content: "\ee8a";
}
.icon-filter3:before {
    content: "\ee8b";
}
.icon-filter4:before {
    content: "\ee8c";
}
.icon-font:before {
    content: "\ee8d";
}
.icon-ampersand:before {
    content: "\ee8e";
}
.icon-ligature:before {
    content: "\ee8f";
}
.icon-ligature2:before {
    content: "\ee90";
}
.icon-font-size:before {
    content: "\ee91";
}
.icon-typography:before {
    content: "\ee92";
}
.icon-text-height:before {
    content: "\ee93";
}
.icon-text-width:before {
    content: "\ee94";
}
.icon-height2:before {
    content: "\ee95";
}
.icon-width:before {
    content: "\ee96";
}
.icon-bold:before {
    content: "\ee97";
}
.icon-underline:before {
    content: "\ee98";
}
.icon-italic:before {
    content: "\ee99";
}
.icon-strikethrough:before {
    content: "\ee9a";
}
.icon-strikethrough2:before {
    content: "\ee9b";
}
.icon-font-size2:before {
    content: "\ee9c";
}
.icon-bold2:before {
    content: "\ee9d";
}
.icon-underline2:before {
    content: "\ee9e";
}
.icon-italic2:before {
    content: "\ee9f";
}
.icon-strikethrough3:before {
    content: "\eea0";
}
.icon-omega:before {
    content: "\eea1";
}
.icon-sigma:before {
    content: "\eea2";
}
.icon-nbsp:before {
    content: "\eea3";
}
.icon-page-break:before {
    content: "\eea4";
}
.icon-page-break2:before {
    content: "\eea5";
}
.icon-superscript:before {
    content: "\eea6";
}
.icon-subscript:before {
    content: "\eea7";
}
.icon-superscript2:before {
    content: "\eea8";
}
.icon-subscript2:before {
    content: "\eea9";
}
.icon-text-color:before {
    content: "\eeaa";
}
.icon-highlight:before {
    content: "\eeab";
}
.icon-pagebreak:before {
    content: "\eeac";
}
.icon-clear-formatting:before {
    content: "\eead";
}
.icon-table:before {
    content: "\eeae";
}
.icon-table2:before {
    content: "\eeaf";
}
.icon-insert-template:before {
    content: "\eeb0";
}
.icon-pilcrow:before {
    content: "\eeb1";
}
.icon-ltr:before {
    content: "\eeb2";
}
.icon-rtl:before {
    content: "\eeb3";
}
.icon-ltr2:before {
    content: "\eeb4";
}
.icon-rtl2:before {
    content: "\eeb5";
}
.icon-section:before {
    content: "\eeb6";
}
.icon-paragraph-left:before {
    content: "\eeb7";
}
.icon-paragraph-center:before {
    content: "\eeb8";
}
.icon-paragraph-right:before {
    content: "\eeb9";
}
.icon-paragraph-justify:before {
    content: "\eeba";
}
.icon-paragraph-left2:before {
    content: "\eebb";
}
.icon-paragraph-center2:before {
    content: "\eebc";
}
.icon-paragraph-right2:before {
    content: "\eebd";
}
.icon-paragraph-justify2:before {
    content: "\eebe";
}
.icon-indent-increase:before {
    content: "\eebf";
}
.icon-indent-decrease:before {
    content: "\eec0";
}
.icon-paragraph-left3:before {
    content: "\eec1";
}
.icon-paragraph-center3:before {
    content: "\eec2";
}
.icon-paragraph-right3:before {
    content: "\eec3";
}
.icon-paragraph-justify3:before {
    content: "\eec4";
}
.icon-indent-increase2:before {
    content: "\eec5";
}
.icon-indent-decrease2:before {
    content: "\eec6";
}
.icon-share:before {
    content: "\eec7";
}
.icon-share2:before {
    content: "\eec8";
}
.icon-new-tab:before {
    content: "\eec9";
}
.icon-new-tab2:before {
    content: "\eeca";
}
.icon-popout:before {
    content: "\eecb";
}
.icon-embed:before {
    content: "\eecc";
}
.icon-embed2:before {
    content: "\eecd";
}
.icon-markup:before {
    content: "\eece";
}
.icon-regexp:before {
    content: "\eecf";
}
.icon-regexp2:before {
    content: "\eed0";
}
.icon-code:before {
    content: "\eed1";
}
.icon-circle-css:before {
    content: "\eed2";
}
.icon-circle-code:before {
    content: "\eed3";
}
.icon-terminal:before {
    content: "\eed4";
}
.icon-unicode:before {
    content: "\eed5";
}
.icon-seven-segment-0:before {
    content: "\eed6";
}
.icon-seven-segment-1:before {
    content: "\eed7";
}
.icon-seven-segment-2:before {
    content: "\eed8";
}
.icon-seven-segment-3:before {
    content: "\eed9";
}
.icon-seven-segment-4:before {
    content: "\eeda";
}
.icon-seven-segment-5:before {
    content: "\eedb";
}
.icon-seven-segment-6:before {
    content: "\eedc";
}
.icon-seven-segment-7:before {
    content: "\eedd";
}
.icon-seven-segment-8:before {
    content: "\eede";
}
.icon-seven-segment-9:before {
    content: "\eedf";
}
.icon-share3:before {
    content: "\eee0";
}
.icon-share4:before {
    content: "\eee1";
}
.icon-mail:before {
    content: "\eee2";
}
.icon-mail2:before {
    content: "\eee3";
}
.icon-mail3:before {
    content: "\eee4";
}
.icon-mail4:before {
    content: "\eee5";
}
.icon-amazon:before {
    content: "\eee6";
}
.icon-google:before {
    content: "\eee7";
}
.icon-google2:before {
    content: "\eee8";
}
.icon-google3:before {
    content: "\eee9";
}
.icon-google-plus:before {
    content: "\eeea";
}
.icon-google-plus2:before {
    content: "\eeeb";
}
.icon-google-plus3:before {
    content: "\eeec";
}
.icon-hangouts:before {
    content: "\eeed";
}
.icon-google-drive:before {
    content: "\eeee";
}
.icon-facebook:before {
    content: "\eeef";
}
.icon-facebook2:before {
    content: "\eef0";
}
.icon-instagram:before {
    content: "\eef1";
}
.icon-whatsapp:before {
    content: "\eef2";
}
.icon-spotify:before {
    content: "\eef3";
}
.icon-telegram:before {
    content: "\eef4";
}
.icon-twitter:before {
    content: "\eef5";
}
.icon-vine:before {
    content: "\eef6";
}
.icon-vk:before {
    content: "\eef7";
}
.icon-renren:before {
    content: "\eef8";
}
.icon-sina-weibo:before {
    content: "\eef9";
}
.icon-rss:before {
    content: "\eefa";
}
.icon-rss2:before {
    content: "\eefb";
}
.icon-youtube:before {
    content: "\eefc";
}
.icon-youtube2:before {
    content: "\eefd";
}
.icon-twitch:before {
    content: "\eefe";
}
.icon-vimeo:before {
    content: "\eeff";
}
.icon-vimeo2:before {
    content: "\ef00";
}
.icon-lanyrd:before {
    content: "\ef01";
}
.icon-flickr:before {
    content: "\ef02";
}
.icon-flickr2:before {
    content: "\ef03";
}
.icon-flickr3:before {
    content: "\ef04";
}
.icon-flickr4:before {
    content: "\ef05";
}
.icon-dribbble:before {
    content: "\ef06";
}
.icon-behance:before {
    content: "\ef07";
}
.icon-behance2:before {
    content: "\ef08";
}
.icon-deviantart:before {
    content: "\ef09";
}
.icon-500px:before {
    content: "\ef0a";
}
.icon-steam:before {
    content: "\ef0b";
}
.icon-steam2:before {
    content: "\ef0c";
}
.icon-dropbox:before {
    content: "\ef0d";
}
.icon-onedrive:before {
    content: "\ef0e";
}
.icon-github:before {
    content: "\ef0f";
}
.icon-npm:before {
    content: "\ef10";
}
.icon-basecamp:before {
    content: "\ef11";
}
.icon-trello:before {
    content: "\ef12";
}
.icon-wordpress:before {
    content: "\ef13";
}
.icon-joomla:before {
    content: "\ef14";
}
.icon-ello:before {
    content: "\ef15";
}
.icon-blogger:before {
    content: "\ef16";
}
.icon-blogger2:before {
    content: "\ef17";
}
.icon-tumblr:before {
    content: "\ef18";
}
.icon-tumblr2:before {
    content: "\ef19";
}
.icon-yahoo:before {
    content: "\ef1a";
}
.icon-yahoo2:before {
    content: "\ef1b";
}
.icon-tux:before {
    content: "\ef1c";
}
.icon-appleinc:before {
    content: "\ef1d";
}
.icon-finder:before {
    content: "\ef1e";
}
.icon-android:before {
    content: "\ef1f";
}
.icon-windows:before {
    content: "\ef20";
}
.icon-windows8:before {
    content: "\ef21";
}
.icon-soundcloud:before {
    content: "\ef22";
}
.icon-soundcloud2:before {
    content: "\ef23";
}
.icon-skype:before {
    content: "\ef24";
}
.icon-reddit:before {
    content: "\ef25";
}
.icon-hackernews:before {
    content: "\ef26";
}
.icon-wikipedia:before {
    content: "\ef27";
}
.icon-linkedin:before {
    content: "\ef28";
}
.icon-linkedin2:before {
    content: "\ef29";
}
.icon-lastfm:before {
    content: "\ef2a";
}
.icon-lastfm2:before {
    content: "\ef2b";
}
.icon-delicious:before {
    content: "\ef2c";
}
.icon-stumbleupon:before {
    content: "\ef2d";
}
.icon-stumbleupon2:before {
    content: "\ef2e";
}
.icon-stackoverflow:before {
    content: "\ef2f";
}
.icon-pinterest:before {
    content: "\ef30";
}
.icon-pinterest2:before {
    content: "\ef31";
}
.icon-xing:before {
    content: "\ef32";
}
.icon-xing2:before {
    content: "\ef33";
}
.icon-flattr:before {
    content: "\ef34";
}
.icon-foursquare:before {
    content: "\ef35";
}
.icon-yelp:before {
    content: "\ef36";
}
.icon-paypal:before {
    content: "\ef37";
}
.icon-chrome:before {
    content: "\ef38";
}
.icon-firefox:before {
    content: "\ef39";
}
.icon-IE:before {
    content: "\ef3a";
}
.icon-edge:before {
    content: "\ef3b";
}
.icon-safari:before {
    content: "\ef3c";
}
.icon-opera:before {
    content: "\ef3d";
}
.icon-file-pdf:before {
    content: "\ef3e";
}
.icon-file-openoffice:before {
    content: "\ef3f";
}
.icon-file-word:before {
    content: "\ef40";
}
.icon-file-excel:before {
    content: "\ef41";
}
.icon-libreoffice:before {
    content: "\ef42";
}
.icon-html-five:before {
    content: "\ef43";
}
.icon-html-five2:before {
    content: "\ef44";
}
.icon-css3:before {
    content: "\ef45";
}
.icon-git:before {
    content: "\ef46";
}
.icon-codepen:before {
    content: "\ef47";
}
.icon-svg:before {
    content: "\ef48";
}
.icon-IcoMoon:before {
    content: "\ef49";
}
.icon-home1:before {
    content: "\ef4a";
}
.icon-home21:before {
    content: "\ef4b";
}
.icon-home31:before {
    content: "\ef4c";
}
.icon-office1:before {
    content: "\ef4d";
}
.icon-newspaper1:before {
    content: "\ef4e";
}
.icon-pencil1:before {
    content: "\ef4f";
}
.icon-pencil21:before {
    content: "\ef50";
}
.icon-quill1:before {
    content: "\ef51";
}
.icon-pen1:before {
    content: "\ef52";
}
.icon-blog1:before {
    content: "\ef53";
}
.icon-eyedropper1:before {
    content: "\ef54";
}
.icon-droplet1:before {
    content: "\ef55";
}
.icon-paint-format1:before {
    content: "\ef56";
}
.icon-image1:before {
    content: "\ef57";
}
.icon-images1:before {
    content: "\ef58";
}
.icon-camera1:before {
    content: "\ef59";
}
.icon-headphones1:before {
    content: "\ef5a";
}
.icon-music1:before {
    content: "\ef5b";
}
.icon-play1:before {
    content: "\ef5c";
}
.icon-film1:before {
    content: "\ef5d";
}
.icon-video-camera1:before {
    content: "\ef5e";
}
.icon-dice1:before {
    content: "\ef5f";
}
.icon-pacman1:before {
    content: "\ef60";
}
.icon-spades1:before {
    content: "\ef61";
}
.icon-clubs1:before {
    content: "\ef62";
}
.icon-diamonds1:before {
    content: "\ef63";
}
.icon-bullhorn1:before {
    content: "\ef64";
}
.icon-connection1:before {
    content: "\ef65";
}
.icon-podcast1:before {
    content: "\ef66";
}
.icon-feed1:before {
    content: "\ef67";
}
.icon-mic1:before {
    content: "\ef68";
}
.icon-book1:before {
    content: "\ef69";
}
.icon-books1:before {
    content: "\ef6a";
}
.icon-library1:before {
    content: "\ef6b";
}
.icon-file-text1:before {
    content: "\ef6c";
}
.icon-profile1:before {
    content: "\ef6d";
}
.icon-file-empty1:before {
    content: "\ef6e";
}
.icon-files-empty1:before {
    content: "\ef6f";
}
.icon-file-text21:before {
    content: "\ef70";
}
.icon-file-picture1:before {
    content: "\ef71";
}
.icon-file-music1:before {
    content: "\ef72";
}
.icon-file-play1:before {
    content: "\ef73";
}
.icon-file-video1:before {
    content: "\ef74";
}
.icon-file-zip1:before {
    content: "\ef75";
}
.icon-copy1:before {
    content: "\ef76";
}
.icon-paste1:before {
    content: "\ef77";
}
.icon-stack1:before {
    content: "\ef78";
}
.icon-folder1:before {
    content: "\ef79";
}
.icon-folder-open1:before {
    content: "\ef7a";
}
.icon-folder-plus1:before {
    content: "\ef7b";
}
.icon-folder-minus1:before {
    content: "\ef7c";
}
.icon-folder-download1:before {
    content: "\ef7d";
}
.icon-folder-upload1:before {
    content: "\ef7e";
}
.icon-price-tag1:before {
    content: "\ef7f";
}
.icon-price-tags1:before {
    content: "\ef80";
}
.icon-barcode1:before {
    content: "\ef81";
}
.icon-qrcode1:before {
    content: "\ef82";
}
.icon-ticket1:before {
    content: "\ef83";
}
.icon-cart1:before {
    content: "\ef84";
}
.icon-coin-dollar1:before {
    content: "\ef85";
}
.icon-coin-euro1:before {
    content: "\ef86";
}
.icon-coin-pound1:before {
    content: "\ef87";
}
.icon-coin-yen1:before {
    content: "\ef88";
}
.icon-credit-card1:before {
    content: "\ef89";
}
.icon-calculator1:before {
    content: "\ef8a";
}
.icon-lifebuoy1:before {
    content: "\ef8b";
}
.icon-phone1:before {
    content: "\ef8c";
}
.icon-phone-hang-up1:before {
    content: "\ef8d";
}
.icon-address-book1:before {
    content: "\ef8e";
}
.icon-envelop1:before {
    content: "\ef8f";
}
.icon-pushpin1:before {
    content: "\ef90";
}
.icon-location1:before {
    content: "\ef91";
}
.icon-location21:before {
    content: "\ef92";
}
.icon-compass1:before {
    content: "\ef93";
}
.icon-compass21:before {
    content: "\ef94";
}
.icon-map1:before {
    content: "\ef95";
}
.icon-map21:before {
    content: "\ef96";
}
.icon-history1:before {
    content: "\ef97";
}
.icon-clock1:before {
    content: "\ef98";
}
.icon-clock21:before {
    content: "\ef99";
}
.icon-alarm1:before {
    content: "\ef9a";
}
.icon-bell1:before {
    content: "\ef9b";
}
.icon-stopwatch1:before {
    content: "\ef9c";
}
.icon-calendar1:before {
    content: "\ef9d";
}
.icon-printer1:before {
    content: "\ef9e";
}
.icon-keyboard1:before {
    content: "\ef9f";
}
.icon-display1:before {
    content: "\efa0";
}
.icon-laptop1:before {
    content: "\efa1";
}
.icon-mobile1:before {
    content: "\efa2";
}
.icon-mobile21:before {
    content: "\efa3";
}
.icon-tablet1:before {
    content: "\efa4";
}
.icon-tv1:before {
    content: "\efa5";
}
.icon-drawer1:before {
    content: "\efa6";
}
.icon-drawer21:before {
    content: "\efa7";
}
.icon-box-add1:before {
    content: "\efa8";
}
.icon-box-remove1:before {
    content: "\efa9";
}
.icon-download1:before {
    content: "\efaa";
}
.icon-upload1:before {
    content: "\efab";
}
.icon-floppy-disk1:before {
    content: "\efac";
}
.icon-drive1:before {
    content: "\efad";
}
.icon-database1:before {
    content: "\efae";
}
.icon-undo1:before {
    content: "\efaf";
}
.icon-redo1:before {
    content: "\efb0";
}
.icon-undo21:before {
    content: "\efb1";
}
.icon-redo21:before {
    content: "\efb2";
}
.icon-forward1:before {
    content: "\efb3";
}
.icon-reply1:before {
    content: "\efb4";
}
.icon-bubble1:before {
    content: "\efb5";
}
.icon-bubbles1:before {
    content: "\efb6";
}
.icon-bubbles21:before {
    content: "\efb7";
}
.icon-bubble21:before {
    content: "\efb8";
}
.icon-bubbles31:before {
    content: "\efb9";
}
.icon-bubbles41:before {
    content: "\efba";
}
.icon-user1:before {
    content: "\efbb";
}
.icon-users1:before {
    content: "\efbc";
}
.icon-user-plus1:before {
    content: "\efbd";
}
.icon-user-minus1:before {
    content: "\efbe";
}
.icon-user-check1:before {
    content: "\efbf";
}
.icon-user-tie1:before {
    content: "\efc0";
}
.icon-quotes-left1:before {
    content: "\efc1";
}
.icon-quotes-right1:before {
    content: "\efc2";
}
.icon-hour-glass1:before {
    content: "\efc3";
}
.icon-spinner1:before {
    content: "\efc4";
}
.icon-spinner21:before {
    content: "\efc5";
}
.icon-spinner31:before {
    content: "\efc6";
}
.icon-spinner41:before {
    content: "\efc7";
}
.icon-spinner51:before {
    content: "\efc8";
}
.icon-spinner61:before {
    content: "\efc9";
}
.icon-spinner71:before {
    content: "\efca";
}
.icon-spinner81:before {
    content: "\efcb";
}
.icon-spinner91:before {
    content: "\efcc";
}
.icon-spinner101:before {
    content: "\efcd";
}
.icon-spinner111:before {
    content: "\efce";
}
.icon-binoculars1:before {
    content: "\efcf";
}
.icon-search1:before {
    content: "\efd0";
}
.icon-zoom-in1:before {
    content: "\efd1";
}
.icon-zoom-out1:before {
    content: "\efd2";
}
.icon-enlarge1:before {
    content: "\efd3";
}
.icon-shrink1:before {
    content: "\efd4";
}
.icon-enlarge21:before {
    content: "\efd5";
}
.icon-shrink21:before {
    content: "\efd6";
}
.icon-key1:before {
    content: "\efd7";
}
.icon-key21:before {
    content: "\efd8";
}
.icon-lock1:before {
    content: "\efd9";
}
.icon-unlocked1:before {
    content: "\efda";
}
.icon-wrench1:before {
    content: "\efdb";
}
.icon-equalizer1:before {
    content: "\efdc";
}
.icon-equalizer21:before {
    content: "\efdd";
}
.icon-cog1:before {
    content: "\efde";
}
.icon-cogs1:before {
    content: "\efdf";
}
.icon-hammer1:before {
    content: "\efe0";
}
.icon-magic-wand1:before {
    content: "\efe1";
}
.icon-aid-kit1:before {
    content: "\efe2";
}
.icon-bug1:before {
    content: "\efe3";
}
.icon-pie-chart1:before {
    content: "\efe4";
}
.icon-stats-dots1:before {
    content: "\efe5";
}
.icon-stats-bars1:before {
    content: "\efe6";
}
.icon-stats-bars21:before {
    content: "\efe7";
}
.icon-trophy1:before {
    content: "\efe8";
}
.icon-gift1:before {
    content: "\efe9";
}
.icon-glass1:before {
    content: "\efea";
}
.icon-glass21:before {
    content: "\efeb";
}
.icon-mug1:before {
    content: "\efec";
}
.icon-spoon-knife1:before {
    content: "\efed";
}
.icon-leaf1:before {
    content: "\efee";
}
.icon-rocket1:before {
    content: "\efef";
}
.icon-meter1:before {
    content: "\eff0";
}
.icon-meter21:before {
    content: "\eff1";
}
.icon-hammer21:before {
    content: "\eff2";
}
.icon-fire1:before {
    content: "\eff3";
}
.icon-lab1:before {
    content: "\eff4";
}
.icon-magnet1:before {
    content: "\eff5";
}
.icon-bin1:before {
    content: "\eff6";
}
.icon-bin21:before {
    content: "\eff7";
}
.icon-briefcase1:before {
    content: "\eff8";
}
.icon-airplane1:before {
    content: "\eff9";
}
.icon-truck1:before {
    content: "\effa";
}
.icon-road1:before {
    content: "\effb";
}
.icon-accessibility1:before {
    content: "\effc";
}
.icon-target1:before {
    content: "\effd";
}
.icon-shield1:before {
    content: "\effe";
}
.icon-power1:before {
    content: "\efff";
}
.icon-switch1:before {
    content: "\f000";
}
.icon-power-cord1:before {
    content: "\f001";
}
.icon-clipboard1:before {
    content: "\f002";
}
.icon-list-numbered1:before {
    content: "\f003";
}
.icon-list1:before {
    content: "\f004";
}
.icon-list21:before {
    content: "\f005";
}
.icon-tree1:before {
    content: "\f006";
}
.icon-menu1:before {
    content: "\f007";
}
.icon-menu21:before {
    content: "\f008";
}
.icon-menu31:before {
    content: "\f009";
}
.icon-menu41:before {
    content: "\f00a";
}
.icon-cloud1:before {
    content: "\f00b";
}
.icon-cloud-download1:before {
    content: "\f00c";
}
.icon-cloud-upload1:before {
    content: "\f00d";
}
.icon-cloud-check1:before {
    content: "\f00e";
}
.icon-download21:before {
    content: "\f00f";
}
.icon-upload21:before {
    content: "\f010";
}
.icon-download31:before {
    content: "\f011";
}
.icon-upload31:before {
    content: "\f012";
}
.icon-sphere1:before {
    content: "\f013";
}
.icon-earth1:before {
    content: "\f014";
}
.icon-link1:before {
    content: "\f015";
}
.icon-flag1:before {
    content: "\f016";
}
.icon-attachment1:before {
    content: "\f017";
}
.icon-eye1:before {
    content: "\f018";
}
.icon-eye-plus1:before {
    content: "\f019";
}
.icon-eye-minus1:before {
    content: "\f01a";
}
.icon-eye-blocked1:before {
    content: "\f01b";
}
.icon-bookmark1:before {
    content: "\f01c";
}
.icon-bookmarks1:before {
    content: "\f01d";
}
.icon-sun1:before {
    content: "\f01e";
}
.icon-contrast1:before {
    content: "\f01f";
}
.icon-brightness-contrast1:before {
    content: "\f020";
}
.icon-star-empty1:before {
    content: "\f021";
}
.icon-star-half1:before {
    content: "\f022";
}
.icon-star-full1:before {
    content: "\f023";
}
.icon-heart1:before {
    content: "\f024";
}
.icon-heart-broken1:before {
    content: "\f025";
}
.icon-man1:before {
    content: "\f026";
}
.icon-woman1:before {
    content: "\f027";
}
.icon-man-woman1:before {
    content: "\f028";
}
.icon-happy1:before {
    content: "\f029";
}
.icon-happy21:before {
    content: "\f02a";
}
.icon-smile1:before {
    content: "\f02b";
}
.icon-smile21:before {
    content: "\f02c";
}
.icon-tongue1:before {
    content: "\f02d";
}
.icon-tongue21:before {
    content: "\f02e";
}
.icon-sad1:before {
    content: "\f02f";
}
.icon-sad21:before {
    content: "\f030";
}
.icon-wink1:before {
    content: "\f031";
}
.icon-wink21:before {
    content: "\f032";
}
.icon-grin1:before {
    content: "\f033";
}
.icon-grin21:before {
    content: "\f034";
}
.icon-cool1:before {
    content: "\f035";
}
.icon-cool21:before {
    content: "\f036";
}
.icon-angry1:before {
    content: "\f037";
}
.icon-angry21:before {
    content: "\f038";
}
.icon-evil1:before {
    content: "\f039";
}
.icon-evil21:before {
    content: "\f03a";
}
.icon-shocked1:before {
    content: "\f03b";
}
.icon-shocked21:before {
    content: "\f03c";
}
.icon-baffled1:before {
    content: "\f03d";
}
.icon-baffled21:before {
    content: "\f03e";
}
.icon-confused1:before {
    content: "\f03f";
}
.icon-confused21:before {
    content: "\f040";
}
.icon-neutral1:before {
    content: "\f041";
}
.icon-neutral21:before {
    content: "\f042";
}
.icon-hipster1:before {
    content: "\f043";
}
.icon-hipster21:before {
    content: "\f044";
}
.icon-wondering1:before {
    content: "\f045";
}
.icon-wondering21:before {
    content: "\f046";
}
.icon-sleepy1:before {
    content: "\f047";
}
.icon-sleepy21:before {
    content: "\f048";
}
.icon-frustrated1:before {
    content: "\f049";
}
.icon-frustrated21:before {
    content: "\f04a";
}
.icon-crying1:before {
    content: "\f04b";
}
.icon-crying21:before {
    content: "\f04c";
}
.icon-point-up1:before {
    content: "\f04d";
}
.icon-point-right1:before {
    content: "\f04e";
}
.icon-point-down1:before {
    content: "\f04f";
}
.icon-point-left1:before {
    content: "\f050";
}
.icon-warning1:before {
    content: "\f051";
}
.icon-notification1:before {
    content: "\f052";
}
.icon-question1:before {
    content: "\f053";
}
.icon-plus1:before {
    content: "\f054";
}
.icon-minus1:before {
    content: "\f055";
}
.icon-info1:before {
    content: "\f056";
}
.icon-cancel-circle1:before {
    content: "\f057";
}
.icon-blocked1:before {
    content: "\f058";
}
.icon-cross1:before {
    content: "\f059";
}
.icon-checkmark1:before {
    content: "\f05a";
}
.icon-checkmark21:before {
    content: "\f05b";
}
.icon-spell-check1:before {
    content: "\f05c";
}
.icon-enter1:before {
    content: "\f05d";
}
.icon-exit1:before {
    content: "\f05e";
}
.icon-play21:before {
    content: "\f05f";
}
.icon-pause1:before {
    content: "\f060";
}
.icon-stop1:before {
    content: "\f061";
}
.icon-previous1:before {
    content: "\f062";
}
.icon-next1:before {
    content: "\f063";
}
.icon-backward1:before {
    content: "\f064";
}
.icon-forward21:before {
    content: "\f065";
}
.icon-play31:before {
    content: "\f066";
}
.icon-pause21:before {
    content: "\f067";
}
.icon-stop21:before {
    content: "\f068";
}
.icon-backward21:before {
    content: "\f069";
}
.icon-forward31:before {
    content: "\f06a";
}
.icon-first1:before {
    content: "\f06b";
}
.icon-last1:before {
    content: "\f06c";
}
.icon-previous21:before {
    content: "\f06d";
}
.icon-next21:before {
    content: "\f06e";
}
.icon-eject1:before {
    content: "\f06f";
}
.icon-volume-high1:before {
    content: "\f070";
}
.icon-volume-medium1:before {
    content: "\f071";
}
.icon-volume-low1:before {
    content: "\f072";
}
.icon-volume-mute1:before {
    content: "\f073";
}
.icon-volume-mute21:before {
    content: "\f074";
}
.icon-volume-increase1:before {
    content: "\f075";
}
.icon-volume-decrease1:before {
    content: "\f076";
}
.icon-loop1:before {
    content: "\f077";
}
.icon-loop21:before {
    content: "\f078";
}
.icon-infinite1:before {
    content: "\f079";
}
.icon-shuffle1:before {
    content: "\f07a";
}
.icon-arrow-up-left1:before {
    content: "\f07b";
}
.icon-arrow-up1:before {
    content: "\f07c";
}
.icon-arrow-up-right1:before {
    content: "\f07d";
}
.icon-arrow-right1:before {
    content: "\f07e";
}
.icon-arrow-down-right1:before {
    content: "\f07f";
}
.icon-arrow-down1:before {
    content: "\f080";
}
.icon-arrow-down-left1:before {
    content: "\f081";
}
.icon-arrow-left1:before {
    content: "\f082";
}
.icon-arrow-up-left21:before {
    content: "\f083";
}
.icon-arrow-up21:before {
    content: "\f084";
}
.icon-arrow-up-right21:before {
    content: "\f085";
}
.icon-arrow-right21:before {
    content: "\f086";
}
.icon-arrow-down-right21:before {
    content: "\f087";
}
.icon-arrow-down21:before {
    content: "\f088";
}
.icon-arrow-down-left21:before {
    content: "\f089";
}
.icon-arrow-left21:before {
    content: "\f08a";
}
.icon-circle-up1:before {
    content: "\f08b";
}
.icon-circle-right1:before {
    content: "\f08c";
}
.icon-circle-down1:before {
    content: "\f08d";
}
.icon-circle-left1:before {
    content: "\f08e";
}
.icon-tab1:before {
    content: "\f08f";
}
.icon-move-up1:before {
    content: "\f090";
}
.icon-move-down1:before {
    content: "\f091";
}
.icon-sort-alpha-asc1:before {
    content: "\f092";
}
.icon-sort-alpha-desc1:before {
    content: "\f093";
}
.icon-sort-numeric-asc1:before {
    content: "\f094";
}
.icon-sort-numberic-desc1:before {
    content: "\f095";
}
.icon-sort-amount-asc1:before {
    content: "\f096";
}
.icon-sort-amount-desc1:before {
    content: "\f097";
}
.icon-command1:before {
    content: "\f098";
}
.icon-shift1:before {
    content: "\f099";
}
.icon-ctrl1:before {
    content: "\f09a";
}
.icon-opt1:before {
    content: "\f09b";
}
.icon-checkbox-checked1:before {
    content: "\f09c";
}
.icon-checkbox-unchecked1:before {
    content: "\f09d";
}
.icon-radio-checked1:before {
    content: "\f09e";
}
.icon-radio-checked21:before {
    content: "\f09f";
}
.icon-radio-unchecked1:before {
    content: "\f0a0";
}
.icon-crop1:before {
    content: "\f0a1";
}
.icon-make-group1:before {
    content: "\f0a2";
}
.icon-ungroup1:before {
    content: "\f0a3";
}
.icon-scissors1:before {
    content: "\f0a4";
}
.icon-filter1:before {
    content: "\f0a5";
}
.icon-font1:before {
    content: "\f0a6";
}
.icon-ligature1:before {
    content: "\f0a7";
}
.icon-ligature21:before {
    content: "\f0a8";
}
.icon-text-height1:before {
    content: "\f0a9";
}
.icon-text-width1:before {
    content: "\f0aa";
}
.icon-font-size1:before {
    content: "\f0ab";
}
.icon-bold1:before {
    content: "\f0ac";
}
.icon-underline1:before {
    content: "\f0ad";
}
.icon-italic1:before {
    content: "\f0ae";
}
.icon-strikethrough1:before {
    content: "\f0af";
}
.icon-omega1:before {
    content: "\f0b0";
}
.icon-sigma1:before {
    content: "\f0b1";
}
.icon-page-break1:before {
    content: "\f0b2";
}
.icon-superscript1:before {
    content: "\f0b3";
}
.icon-subscript1:before {
    content: "\f0b4";
}
.icon-superscript21:before {
    content: "\f0b5";
}
.icon-subscript21:before {
    content: "\f0b6";
}
.icon-text-color1:before {
    content: "\f0b7";
}
.icon-pagebreak1:before {
    content: "\f0b8";
}
.icon-clear-formatting1:before {
    content: "\f0b9";
}
.icon-table1:before {
    content: "\f0ba";
}
.icon-table21:before {
    content: "\f0bb";
}
.icon-insert-template1:before {
    content: "\f0bc";
}
.icon-pilcrow1:before {
    content: "\f0bd";
}
.icon-ltr1:before {
    content: "\f0be";
}
.icon-rtl1:before {
    content: "\f0bf";
}
.icon-section1:before {
    content: "\f0c0";
}
.icon-paragraph-left1:before {
    content: "\f0c1";
}
.icon-paragraph-center1:before {
    content: "\f0c2";
}
.icon-paragraph-right1:before {
    content: "\f0c3";
}
.icon-paragraph-justify1:before {
    content: "\f0c4";
}
.icon-indent-increase1:before {
    content: "\f0c5";
}
.icon-indent-decrease1:before {
    content: "\f0c6";
}
.icon-share1:before {
    content: "\f0c7";
}
.icon-new-tab1:before {
    content: "\f0c8";
}
.icon-embed1:before {
    content: "\f0c9";
}
.icon-embed21:before {
    content: "\f0ca";
}
.icon-terminal1:before {
    content: "\f0cb";
}
.icon-share21:before {
    content: "\f0cc";
}
.icon-mail1:before {
    content: "\f0cd";
}
.icon-mail21:before {
    content: "\f0ce";
}
.icon-mail31:before {
    content: "\f0cf";
}
.icon-mail41:before {
    content: "\f0d0";
}
.icon-amazon1:before {
    content: "\f0d1";
}
.icon-google1:before {
    content: "\f0d2";
}
.icon-google21:before {
    content: "\f0d3";
}
.icon-google31:before {
    content: "\f0d4";
}
.icon-google-plus1:before {
    content: "\f0d5";
}
.icon-google-plus21:before {
    content: "\f0d6";
}
.icon-google-plus31:before {
    content: "\f0d7";
}
.icon-hangouts1:before {
    content: "\f0d8";
}
.icon-google-drive1:before {
    content: "\f0d9";
}
.icon-facebook1:before {
    content: "\f0da";
}
.icon-facebook21:before {
    content: "\f0db";
}
.icon-instagram1:before {
    content: "\f0dc";
}
.icon-whatsapp1:before {
    content: "\f0dd";
}
.icon-spotify1:before {
    content: "\f0de";
}
.icon-telegram1:before {
    content: "\f0df";
}
.icon-twitter1:before {
    content: "\f0e0";
}
.icon-vine1:before {
    content: "\f0e1";
}
.icon-vk1:before {
    content: "\f0e2";
}
.icon-renren1:before {
    content: "\f0e3";
}
.icon-sina-weibo1:before {
    content: "\f0e4";
}
.icon-rss1:before {
    content: "\f0e5";
}
.icon-rss21:before {
    content: "\f0e6";
}
.icon-youtube1:before {
    content: "\f0e7";
}
.icon-youtube21:before {
    content: "\f0e8";
}
.icon-twitch1:before {
    content: "\f0e9";
}
.icon-vimeo1:before {
    content: "\f0ea";
}
.icon-vimeo21:before {
    content: "\f0eb";
}
.icon-lanyrd1:before {
    content: "\f0ec";
}
.icon-flickr1:before {
    content: "\f0ed";
}
.icon-flickr21:before {
    content: "\f0ee";
}
.icon-flickr31:before {
    content: "\f0ef";
}
.icon-flickr41:before {
    content: "\f0f0";
}
.icon-dribbble1:before {
    content: "\f0f1";
}
.icon-behance1:before {
    content: "\f0f2";
}
.icon-behance21:before {
    content: "\f0f3";
}
.icon-deviantart1:before {
    content: "\f0f4";
}
.icon-500px1:before {
    content: "\f0f5";
}
.icon-steam1:before {
    content: "\f0f6";
}
.icon-steam21:before {
    content: "\f0f7";
}
.icon-dropbox1:before {
    content: "\f0f8";
}
.icon-onedrive1:before {
    content: "\f0f9";
}
.icon-github1:before {
    content: "\f0fa";
}
.icon-npm1:before {
    content: "\f0fb";
}
.icon-basecamp1:before {
    content: "\f0fc";
}
.icon-trello1:before {
    content: "\f0fd";
}
.icon-wordpress1:before {
    content: "\f0fe";
}
.icon-joomla1:before {
    content: "\f0ff";
}
.icon-ello1:before {
    content: "\f100";
}
.icon-blogger1:before {
    content: "\f101";
}
.icon-blogger21:before {
    content: "\f102";
}
.icon-tumblr1:before {
    content: "\f103";
}
.icon-tumblr21:before {
    content: "\f104";
}
.icon-yahoo1:before {
    content: "\f105";
}
.icon-yahoo21:before {
    content: "\f106";
}
.icon-tux1:before {
    content: "\f107";
}
.icon-appleinc1:before {
    content: "\f108";
}
.icon-finder1:before {
    content: "\f109";
}
.icon-android1:before {
    content: "\f10a";
}
.icon-windows1:before {
    content: "\f10b";
}
.icon-windows81:before {
    content: "\f10c";
}
.icon-soundcloud1:before {
    content: "\f10d";
}
.icon-soundcloud21:before {
    content: "\f10e";
}
.icon-skype1:before {
    content: "\f10f";
}
.icon-reddit1:before {
    content: "\f110";
}
.icon-hackernews1:before {
    content: "\f111";
}
.icon-wikipedia1:before {
    content: "\f112";
}
.icon-linkedin1:before {
    content: "\f113";
}
.icon-linkedin21:before {
    content: "\f114";
}
.icon-lastfm1:before {
    content: "\f115";
}
.icon-lastfm21:before {
    content: "\f116";
}
.icon-delicious1:before {
    content: "\f117";
}
.icon-stumbleupon1:before {
    content: "\f118";
}
.icon-stumbleupon21:before {
    content: "\f119";
}
.icon-stackoverflow1:before {
    content: "\f11a";
}
.icon-pinterest1:before {
    content: "\f11b";
}
.icon-pinterest21:before {
    content: "\f11c";
}
.icon-xing1:before {
    content: "\f11d";
}
.icon-xing21:before {
    content: "\f11e";
}
.icon-flattr1:before {
    content: "\f11f";
}
.icon-foursquare1:before {
    content: "\f120";
}
.icon-yelp1:before {
    content: "\f121";
}
.icon-paypal1:before {
    content: "\f122";
}
.icon-chrome1:before {
    content: "\f123";
}
.icon-firefox1:before {
    content: "\f124";
}
.icon-IE1:before {
    content: "\f125";
}
.icon-edge1:before {
    content: "\f126";
}
.icon-safari1:before {
    content: "\f127";
}
.icon-opera1:before {
    content: "\f128";
}
.icon-file-pdf1:before {
    content: "\f129";
}
.icon-file-openoffice1:before {
    content: "\f12a";
}
.icon-file-word1:before {
    content: "\f12b";
}
.icon-file-excel1:before {
    content: "\f12c";
}
.icon-libreoffice1:before {
    content: "\f12d";
}
.icon-html-five1:before {
    content: "\f12e";
}
.icon-html-five21:before {
    content: "\f12f";
}
.icon-css31:before {
    content: "\f130";
}
.icon-git1:before {
    content: "\f131";
}
.icon-codepen1:before {
    content: "\f132";
}
.icon-svg1:before {
    content: "\f133";
}
.icon-IcoMoon1:before {
    content: "\f134";
}
